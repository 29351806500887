import Utils from "Services/Utils"
import Title from "components/Header/Title"
import TooltipInfo from "components/TooltipInfo"
import { BUILDER_CONFIG, CHOICE_DISPLAY_VALUE, CHOICE_HELP_TEXT, CHOICE_ID, CHOICE_IS_EXCLUDED, CHOICE_NAME, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, EXCLUSION_OPTIONS, FIELD_ADDITIONAL_DATA, FIELD_DATA_CONFIGURATION, FRMDATA_FIELD_EXCLUSION, PAGE_LAYOUT, PAGE_LAYOUTS, SORT, THEME_FILL_BUTTON_BG, THEME_FILL_BUTTON_CLR, THEME_PLATFORMS, THEME_SECONDARY, THEMES } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import { FaCheck } from "react-icons/fa"
import { TChoice, TInfoText, TUI_Node } from "types"

type MyProps = {
    id: string
    name: string
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    description?: string
    sort?: boolean
    info?: TInfoText
    exclusionBehaviour?: string
    dataLoading: boolean
    showLabel?: boolean
}

const CheckBoxes = (props: MyProps) => {
    const {builderSpec, activePage, platform} = useAppContext()
    if(!props.items) return <>No values found</>

    const handleSelect = (choice: TChoice, isSelected: boolean) => {
        /**
         * if value is already selected remove it from the array
         * and pass it to the select function else vice versa
         */
        if(!props.onChange) return

        if(!props.isMulti) {
            props.onChange(isSelected ? [] : choice[CHOICE_ID])
            return
        }

        const newValues = isArray(props.value) ? [...NodeHelper.getChoicesIds(props.value)] : []
        if(isSelected){
            const index = newValues.indexOf(choice[CHOICE_ID])
            if(index !== -1) newValues.splice(index, 1)
        }
        else{
            newValues.push(choice[CHOICE_ID])
        }
        props.onChange(newValues)
    }

    const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

    // const allDisabled = Utils.checkIfAllChoicesAreDisabled(props.items)

    if(props.items?.length === 0) return (
        <div className="sb3-space-y-2">
            <Title title={props.name} required={props.required} description={props.description} info={props.info} id={props.id}  showLabel={props.showLabel}/>
            <p className="sb3-text-gray-extraDark sb3-text-center sb3-text-sm">No options</p>
        </div>
    )


  const styles: any = {}

    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]) {
        styles.backgroundColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.borderColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
    }
    const disabledStyles = {
        backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY]
    }
   
    return (
        <div className="sb3-space-y-2">
            <Title title={props.name} required={props.required} description={props.description} info={props.info} showLabel={props.showLabel} id={props.id}/>
            <div className={
                (platform === THEME_PLATFORMS.BIG_COMMERCE && props.items?.length > 10 && activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING) ? "sb3-grid md:sb3-grid-cols-3 lg:sb3-grid-cols-4 sm:sb3-grid-cols-2 sb3-grid-cols-1 sb3-gap-2"
                : (props.items?.length > 10) ? "sb3-gap-2 sb3-grid sb3-grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))]" : "sb3-space-y-2"
            }>
            {
                (props.items as any)
                ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
                .sort(
                    (props.sort || props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER) ? 
                    Utils.sortChoices(
                        true, 
                        (props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER && props.sort) ? SORT.SEPERATE_ASC :
                        (props.sort ?  SORT.ASC : undefined), (item: any) => !!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME], 
                        (item: any) => item[CHOICE_IS_EXCLUDED]
                    ) as any : undefined
                )
                .map((item: TChoice, key: number) => {
                    const _selected = valuesIds?.includes(item?.[CHOICE_ID])
                    const _disabled = item[CHOICE_IS_EXCLUDED] || props.disabled
                    return (
                        <div key={key}>
                            <input checked={_selected} onChange={(e) => !props.dataLoading && handleSelect(item, _selected)} disabled={props.disabled} id={"checkbox-"+item[CHOICE_ID]} type="checkbox" className="custom-checkbox sb3-hidden"/>
                            <label htmlFor={"checkbox-"+item[CHOICE_ID]} className="sb3-cursor-pointer sb3-flex">
                                <span 
                                tabIndex={props.disabled ? -1 : 0}
                                className={`sb3-w-4 sb3-mt-0.5 sb3-h-4 sb3-border sb3-bg-white ${platform === THEME_PLATFORMS.BIG_COMMERCE && "!sb3-border-2"} ${(_disabled && platform !== THEME_PLATFORMS.BIG_COMMERCE) && '!sb3-bg-gray-400'} sb3-border-gray-300 sb3-rounded sb3-flex-shrink-0 sb3-relative checkmark`}
                                style = {_selected ? styles : (_disabled && platform === THEME_PLATFORMS.BIG_COMMERCE) ? disabledStyles : {}}
                                >

                                {_selected && (
                                    <FaCheck className="sb3-text-sm sb3-text-white sb3-p-0.5"/>
                                )}
                                </span>
                                <span className={`sb3-ml-2 sb3-text-sm sb3-pr-1  ${_disabled ? 'sb3-text-gray-400' : "sb3-text-black"}`}>{(!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME])}</span><TooltipInfo data={item[CHOICE_HELP_TEXT]} id={`choice-${props.id}-${item[CHOICE_ID]}`} title={(!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME])}/>
                            </label>
                        </div>
                )})
            }
        </div>
    </div>
    )
}

export default CheckBoxes