import FillButton from "components/Button/FillButton"
import ProductHelper from "helpers/ProductHelper"
import { ProductPriceString } from "./ProductPriceString"
import { useAppContext } from "contexts/AppContext"
import {  CATEGORY_NAME, MODEL_DATA, PLACEHOLDER_IMAGE, RESULT_PRDT_CATEGORY_ID, RESULT_PRDT_DESC, RESULT_PRDT_NAME, RESULT_PRDT_OPTIONS, RESULT_PRDT_PRICE,  RESULT_PRDT_SKU,  RUNTIME_INSTANCE_INSTANCE_ID, THEME_PLATFORMS } from "constants/constants"
import QuantityInput from "./QuantityInput"
import { TProductProps } from "./types"
import BorderlessButton from "components/Button/BorderlessButton"
import ProductStockStatus from "./ProductStockStatus"
import BorderedButton from "components/Button/BorderedButton"
import Utils from "Services/Utils"
import ReactSelect from "react-select"
import { useEffect, useState } from "react"
import { isArray } from "lodash"
import { TResultOption, TResultProduct } from "types"
import ProductDescriptionTooltip from "components/ProductDescriptionTooltip"
import CartIcon from "./CartIcon"

const ProductVertical = ({
    category,
    product,
    selected,
    instance,
    showQtyInput,
    quantity,
    maxQty,
    buttonLabel,
    type,
    handleProductClick,
    handleQtyChange,
    showImage
}: TProductProps) => { 
    const {
        addProductToCart,
        removeProductFromCart,
        dataLoading,
        theme,
        setImagePreview,
        platform
    } = useAppContext()

    const [selectedVariant, setSelectedVariant] = useState<TResultProduct|undefined>()

    useEffect(() => {
        if (isArray(product[RESULT_PRDT_OPTIONS]) 
            && product[RESULT_PRDT_OPTIONS]?.length > 0 ){
            setSelectedVariant(product[RESULT_PRDT_OPTIONS][0])
        }
    }, [])
    
    const options = product[RESULT_PRDT_OPTIONS]
        // ?.sort(props.sort ? Utils.sortChoices(true, SORT.SEPERATE_ASC, (item: any) => item[CHOICE_NAME], (item: any) => item[CHOICE_IS_DISABLED]) as any : undefined)
        ?.reduce((acc: {value: string, label: string}[], choice) => {
            acc.push({value: choice[RESULT_PRDT_SKU], label: choice.variantTitle ?? choice[RESULT_PRDT_NAME]})
            return acc
        }
    , [])

    const hasOptions = isArray(product[RESULT_PRDT_OPTIONS]) 
        && product[RESULT_PRDT_OPTIONS]?.length > 1 

    const productImage = ProductHelper.getProductImage(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])
    
    return (
        <div className={`sb3-bg-white sb3-product-card sb3-product-grid sb3-w-full sb3-border sb3-p-2 sb3-h-full sb3-flex sb3-flex-col sb3-justify-between sb3-rounded ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-py-4 sb3-rounded-[24px] hover:sb3-shadow-lg" : "sb3-bg-white sb3-py-10  "} `}>
        {
            showImage !== false && 
            <span>
                <img 
                key={product[RESULT_PRDT_SKU]}
                onClick={() => setImagePreview(!!productImage ? productImage : PLACEHOLDER_IMAGE)}  
                src={!!productImage ? productImage : PLACEHOLDER_IMAGE} 
                alt="Product" 
                className={`sb3-aspect-square sb3-w-full sb3-max-w-full sb3-object-contain ${platform === THEME_PLATFORMS.BIG_COMMERCE ? 'sb3-rounded-[21px] sb3-overflow-hidden':'sb3-rounded-none' }`}
                />
            </span>
        }
       
    
        <div className="sb3-flex sb3-flex-col sb3-space-y-2 sb3-justify-between sb3-flex-grow sb3-h-full sb3-px-1">
            <div className={`${Utils.isLinear(theme) && "sb3-text-center"} sb3-flex-grow sb3-space-y-2 sb3-flex sb3-flex-col`}>
    
                {
                    product[RESULT_PRDT_OPTIONS] && hasOptions
                    && <span className="sb3-text-xs sb3-text-gray-extraDark">{product[RESULT_PRDT_OPTIONS].length} options</span>
                }
    
                <button type="button" className={`sb3-text-sm sb3-mt-2 sb3-line-clamp-2 sb3-border-0 ${Utils.isLinear(theme) ? "sb3-text-center" : "sb3-text-left"} sb3-w-full hover:sb3-bg-transparent hover:sb3-border-0 focus:sb3-bg-0 focus:sb3-border-transparent focus:!sb3-outline-none`} 
                onClick={() => handleProductClick(selectedVariant ?? product)}>{ProductHelper.getProductPrimaryTitle(product, platform)}</button>
    
                {!Utils.isShopify() && <p className="sb3-text-gray-extraDark sb3-line-clamp-3 sb3-text-xs">{ProductHelper.getProductSecondaryTitle(product, platform)}</p>}
               {!Utils.isLinear(theme) && <p className="sb3-text-sm sb3-text-gray-extraDark sb3-line-clamp-1 sb3-font-semibold">{ProductHelper.getProductTertiaryTitle(product, platform)}</p>}
    
                {
                    platform !== THEME_PLATFORMS.BIG_COMMERCE && 
                    <p className="sb3-text-sm sb3-font-semibold">
                        <ProductPriceString
                            price={ProductHelper.getProductPrice(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])}
                            // compareAtPrice={product.compareAtPrice}
                            // currency={product.currency}
                    /></p>
                }
                    
                {
                    isArray(product[RESULT_PRDT_OPTIONS]) 
                    && product[RESULT_PRDT_OPTIONS]?.length > 1 
                    && <ReactSelect
                        value={options?.filter((item) => (selectedVariant)?.[RESULT_PRDT_SKU] === item.value)}
                        options={options}
                        onChange={(selection) => setSelectedVariant(product[RESULT_PRDT_OPTIONS]?.find(pr => pr[RESULT_PRDT_SKU] === selection?.value))}
                        menuPosition="fixed"
                        styles={{
                            control: (provided, state) => ({
                            ...provided,
                                borderRadius: 0,
                                minWidth: 100,
                                background: "#F9F9F9"
                            })
                        }}
                    />
                }
    
                {(selectedVariant?.[RESULT_PRDT_DESC] || product[RESULT_PRDT_DESC]) && <ProductDescriptionTooltip message={selectedVariant?.[RESULT_PRDT_DESC] ?? product[RESULT_PRDT_DESC]}/>}
    
               
            </div>
    
               
            <div className="sb3-flex sb3-flex-col sb3-space-y-2 sb3-justify-center sb3-w-full">
                {
                    !Utils.isLinear(theme) && <span className="sb3-flex sb3-items-center sb3-max-w-full sb3-text-sm sb3-space-x-2">
                        <ProductStockStatus product={selectedVariant ?? product}/>
                    </span> 
                }

                <div className="sb3-flex sb3-justify-between sb3-items-center">
                {
                    platform === THEME_PLATFORMS.BIG_COMMERCE &&<p className="sb3-text-sm sb3-font-semibold">
                    <ProductPriceString
                        price={ProductHelper.getProductPrice(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])}
                        // compareAtPrice={product.compareAtPrice}
                        // currency={product.currency}
                    /></p>
                    
                }
                {
                    platform === THEME_PLATFORMS.BIG_COMMERCE &&selected &&
                    (maxQty ?? 1) > 1 && quantity && 
                    <QuantityInput
                        quantity={quantity}
                        handleChange={handleQtyChange}
                        disablePrev={(qty: number) => qty === 1}
                        disableNext={() => !showQtyInput}
                    />
                }
                {
                    platform !== THEME_PLATFORMS.BIG_COMMERCE && !selected && 
                    <BorderedButton 
                    disabled={!showQtyInput || dataLoading} 
                    onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] 
                        && addProductToCart(selectedVariant ?? product, 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])
                    } className="!sb3-px-6 sb3-m-auto sb3-w-full">{
                        buttonLabel ?? "ADD TO BUILD"
                    }</BorderedButton>
                }
                
                {
                    platform === THEME_PLATFORMS.BIG_COMMERCE && !selected && 
                    <FillButton disabled={!showQtyInput || dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && addProductToCart(selectedVariant ?? product, 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])} 
                    className="!sb3-w-min !sb3-min-w-min !sb3-rounded-full">
                        <CartIcon/>
                    </FillButton>
                }
                </div>
    
                
                {
                    selected &&
                    <div className={platform !== THEME_PLATFORMS.BIG_COMMERCE ? "sb3-flex sb3-items-center sb3-justify-center" : "sb3-text-center"}>
                    {
                        platform !== THEME_PLATFORMS.BIG_COMMERCE &&(maxQty ?? 1) > 1 && quantity && 
                        <QuantityInput
                            quantity={quantity}
                            handleChange={handleQtyChange}
                            disablePrev={(qty: number) => qty === 1}
                            disableNext={() => !showQtyInput}
                        />
                        }
                        
                        <BorderlessButton 
                        disabled={dataLoading} 
                        onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] 
                        && removeProductFromCart(selectedVariant ?? product, product[RESULT_PRDT_CATEGORY_ID], instance[RUNTIME_INSTANCE_INSTANCE_ID])} 
                        className="!sb3-min-w-min sb3-text-sm !sb3-text-danger hover:!sb3-text-danger">{
                            "remove"
                        }</BorderlessButton> 
                        
                    </div>
                    
                }
            </div>
        </div>
    </div>
    )
}

export default ProductVertical