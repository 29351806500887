import ReactSelect, { MultiValue, SingleValue, StylesConfig } from "react-select";
import Title from "../Header/Title";
import { TChoice, TInfoText } from "types";
import { CHOICE_DISPLAY_VALUE, CHOICE_ID, CHOICE_IS_EXCLUDED, CHOICE_NAME, EXCLUSION_OPTIONS, SORT } from "constants/constants";
import Utils from "Services/Utils";
import NodeHelper from "helpers/NodeHelper";

type TOption = {
    label: string, value: string, disabled: boolean
}

type MyProps = {
    name: string
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    description?: string
    info?: TInfoText
    sort?: boolean
    exclusionBehaviour?: string
    dataLoading: boolean
    showLabel?: boolean
    id: string
}
const Select = (props: MyProps) => {
  const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

  const colourStyles: StylesConfig<TOption> = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.disabled ? '#9e9e9e'
        : isSelected
          ? 'white'
          : 'black',
        cursor: 'default',
      };
    },
    control: (provided, state) => ({
      ...provided,
        borderRadius: 0,
        minWidth: 100
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      padding: 0,
  }),
  };

  const options: TOption[]|undefined = props.items
  ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
  .sort(
    (props.sort || props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER) ? 
    Utils.sortChoices(
      true, 
      (props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER && props.sort) ? SORT.SEPERATE_ASC :
      (props.sort ?  SORT.ASC : undefined), (item: any) => !!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME], 
      (item: any) => item[CHOICE_IS_EXCLUDED]
    ) as any : undefined
)
  .reduce((acc: {value: string, label: string, disabled: boolean}[], choice) => {
      acc.push({value: choice[CHOICE_ID], label: !!choice[CHOICE_DISPLAY_VALUE] ? choice[CHOICE_DISPLAY_VALUE]: choice[CHOICE_NAME], disabled: !!choice[CHOICE_IS_EXCLUDED]})
      return acc
  }
  , [])

  const allDisabled = props.items?.length === 0 || props.items?.every((choice: TChoice) => choice[CHOICE_IS_EXCLUDED]) || props.disabled

  const handleChange = (value: MultiValue<TOption> | SingleValue<TOption>) => {
    if(props.isMulti) props.onChange((value as MultiValue<TOption>).map(value => value.value))
    else props.onChange((value as SingleValue<TOption>)?.value)
  }

  return (
    <div className="sb3-space-y-2">
        <Title title={props.name} required={props.required} description={props.description} info={props.info} showLabel={props.showLabel} id={props.id}/>
        <div 
          className={!props.isFilter ? '!sb3-w-60' : 'sb3-w-full'}
        >
          
          <ReactSelect 
            value = {options?.filter((item) => valuesIds.includes(item.value))}
            isDisabled={allDisabled} 
            options={options} 
            onChange={(selection) => !props.dataLoading && handleChange(selection)} 
            styles={colourStyles}
            isMulti={props.isMulti}
            menuPosition="fixed"
            isClearable={true}
            />
        </div>
    </div>
  )  
};

export default Select