import AccordionBody from "components/Accordion/AccordionBody";
import AccordionHeader from "components/Accordion/AccordionHeader";
import AccordionLayout from "components/Accordion/AccordionLayout";
import BorderedButton from "components/Button/BorderedButton";
import BorderlessButton from "components/Button/BorderlessButton";
import FillButton from "components/Button/FillButton";
import Cart from "components/Cart";
import SubmodelSummary from "components/Cart/SubmodelSummary";
import Filters from "components/Filters";
import Header from "components/Header";
import PageModelIterator from "components/Iterators/PageModelIterator";
import Submodel from "components/Submodel";
import {
    BUILDER_CONFIG,
    CATEGORY_ID,
    CONFIG_CUSTOMIZATION,
    CUSTOMIZATION_THEME_SETTINGS,
    FIELD_ID,
    FIELD_NAME,
    FIELD_PLACEMENT,
    FIELD_TYPE,
    FIELD_TYPES,
    FRMDATA_ITEMS,
    FRMDATA_PLACEMENT,
    FRMDATA_SMID,
    MODEL_CATEGORIES,
    MODEL_DATA,
    MODEL_DYNAMIC_FIELDS,
    MODEL_FIELDS,
    MODEL_SUBMODEL_ID,
    PAGE_AUTO_ADVANCE,
    RUNTIME_INSTANCE_INSTANCE_ID,
    RUNTIME_INSTANCE_IS_INPUT,
    RUNTIME_INSTANCE_MAX_COUNT,
    RUNTIME_INSTANCE_MAX_QUANTITY,
    THEME_BACKGROUND,
    THEME_PLATFORMS,
    THEME_SECONDARY,
    THEME_SUBTITLES,
} from "constants/constants";
import { useAppContext } from "contexts/AppContext";
// import { AnimatePresence, m } from "framer-motion"
import NodeHelper from "helpers/NodeHelper";
import { isArray } from "lodash";
import { FaRegTrashAlt } from "react-icons/fa";
import Utils from "Services/Utils";
import {
    TModel,
    TProductCategory,
    TProductCategoryField,
    TRuntimeInstance,
    TUI_Node,
} from "types";
import { TCartItem } from "types/Cart";

const ConfiguratorLayout = () => {
    const {
        loading,
        // activeSubmodel,
        builderSpec,
        specs,
        cart,
        activePage,
        getInstances,
        platform,
        theme,
        dataLoading,
        deleteInstance,
        setFilterOpen,
        isFilterOpen,
        categoryProductsCount,
        activePageIndex,
        handleNextPage,
        handlePrevPage
    } = useAppContext();

    return (
        <>
            {
                isFilterOpen &&
                <div className="sb3-fixed sb3-h-screen sb3-max-h-screen sb3-overflow-auto lg:sb3-hidden sb3-w-full sb3-bg-white sb3-top-0 sb3-left-0 sb3-px-2 sb3-z-[1002]">
                    <div className="sb3-flex sb3-items-center sb3-justify-between sb3-py-2">
                        <h3 style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES]}}>Filter</h3>

                        <BorderedButton onClick = {() => setFilterOpen(undefined)} className="sb3-text-sm lg:sb3-hidden sb3-block">Close</BorderedButton>
                    </div>

                        
                    <Filters activeSubmodel={isFilterOpen}/>
                </div>
            }

            <div className="sb3-flex-grow">
                {
                    <PageModelIterator>
                        {({
                            visibleFields,
                            // visibleConnectors,
                            visibleCategories,
                            disableInstantiation,
                            title,
                            isNumbered,
                            number,
                            instances,
                            _minInstantiation,
                            _maxInstantiation,
                            isInstantiable,
                            instance,
                            model,
                            uiModel,
                        }) => {
                            const activeSubmodel = activePage && {
                                ...specs[uiModel[FRMDATA_SMID]],
                                instance: getInstances(uiModel[FRMDATA_SMID])?.[0],
                                uiModel: uiModel,
                            };
                        
                            let selectedProducts: TCartItem[] = [];
                        
                            if (
                                activeSubmodel?.[MODEL_SUBMODEL_ID] &&
                                activeSubmodel.instance &&
                                specs[activeSubmodel?.[MODEL_SUBMODEL_ID]]?.[MODEL_DATA]?.[
                                MODEL_CATEGORIES
                                ]?.[0]?.[CATEGORY_ID]
                            ) {
                                selectedProducts = Utils.getSelectedProductsFromCategoryIds(
                                    cart,
                                    specs[activeSubmodel[MODEL_SUBMODEL_ID]][MODEL_DATA][
                                        MODEL_CATEGORIES
                                    ].map((cat: TProductCategory) => cat[CATEGORY_ID]),
                                    activeSubmodel.instance[RUNTIME_INSTANCE_INSTANCE_ID]
                                );
                            }
                        
                            const visibleNodes =
                                activeSubmodel?.instance?.[MODEL_DYNAMIC_FIELDS] &&
                                NodeHelper.filterVisibleNodesUsingRuntime(
                                    activeSubmodel.instance[MODEL_DYNAMIC_FIELDS],
                                    activeSubmodel.uiModel
                                );
                        
                            const isFilterActive =
                                activeSubmodel.uiModel?.[FRMDATA_ITEMS]
                                // (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)[MODEL_DATA][MODEL_FIELDS]
                                .filter((fieldB: TUI_Node) => {
                                    const fieldR = activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

                                    return fieldB[FRMDATA_PLACEMENT] !== FIELD_PLACEMENT.GUIDED_SELLING 
                                        && fieldR && visibleNodes.visibleFieldIds.includes(fieldB[FIELD_ID]) 
                                        && fieldR[RUNTIME_INSTANCE_IS_INPUT] 
                                }).length > 0 &&
                                isArray(visibleNodes?.visibleFieldIds) &&
                                (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][
                                    MODEL_FIELDS
                                ].filter((field) => visibleNodes.visibleFieldIds.includes(field[FIELD_ID]))
                                    .length > 0 &&
                                (specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][
                                    MODEL_CATEGORIES
                                ].length === 0
                                ||
                                specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][
                                    MODEL_CATEGORIES
                                ]?.some(
                                    (cat: TProductCategoryField) =>
                                        (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][
                                            RUNTIME_INSTANCE_MAX_QUANTITY
                                        ] ?? 1) >
                                        Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) &&
                                        (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][
                                            RUNTIME_INSTANCE_MAX_COUNT
                                        ] ?? 1) >
                                        Utils.getUniquePrdtCountByCategoryId(
                                            selectedProducts,
                                            cat[CATEGORY_ID]
                                        )
                                ));

                            return (
                            <div
                                key={number}
                                className={`sb3-space-y-1  sb3-rounded-none sb3-pb-5 ${Utils.isConfigurator(theme)
                                        ? `sb3-px-3 ${platform !== THEME_PLATFORMS.BIG_COMMERCE && ""
                                        }`
                                        : "sb3-border-b sb3-border-gray sb3-last:border-0"
                                    }`}
                            >
                                <AccordionLayout disabled={true} initialState={true}>
                                    {({ isOpen, ...provided }) => (
                                        <div>
                                            {
                                                (!!title || uiModel.items.some((item) => item[FIELD_TYPE] === FIELD_TYPES.CATEGORY) || isInstantiable) && <div className={`
                                                ${Utils.isLinear(theme) ? "sb3-top-0" : " sb3-top-[56px]"}
                                                sb3-sticky sb3-bg-white sb3-z-10  sb3-border-b sb3-rounded`}
                                                style={{backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_BACKGROUND]}}
                                                >
                                                <AccordionHeader {...provided.getHeaderProps()}>
                                                    <span className="sb3-flex sb3-justify-between sb3-items-center ">
                                                        {title ? (
                                                            <Header
                                                                title={title}
                                                                number={isNumbered ? number : undefined}
                                                                className={
                                                                    !Utils.isConfigurator(theme) ? "!sb3-text-xl" : "!sb3-text-xl"
                                                                }
                                                            />
                                                        ) : <span></span>}

                                                        <div className="sb3-flex sb3-gap-2">
                                                            <div className="sb3-flex sb3-gap-2 sb3-flex-wrap sb3-justify-end sb3-min-h-9 sb3-items-center">
                                                            {
                                                                categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]] 
                                                                && Object.entries(categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]])
                                                                .sort(([catId1, ], [catId2, ]) => uiModel.items.findIndex(item => item.id === catId1) - uiModel.items.findIndex(item => item.id === catId2))
                                                                .map(([catId, productCount], key) => {
                                                                    return <span key={key}>
                                                                        <span className="">{model.data.productCategories.find(_category => _category[FIELD_ID] === catId)?.[MODEL_DATA][FIELD_NAME]}</span>: {productCount > 50 ? "50+" : productCount} Compatible Selections
                                                                    </span>
                                                                })
                                                            }
                                                            </div>
                                                        
                                                            <div className="sb3-flex sb3-space-x-2 sb3-items-center">
                                                                {!disableInstantiation && isInstantiable && (
                                                                    <BorderlessButton
                                                                        className="sb3-min-w-min"
                                                                        disabled={
                                                                            instances.length <= _minInstantiation ||
                                                                            dataLoading
                                                                        }
                                                                        onClick={() =>
                                                                            deleteInstance(
                                                                                (instance as TRuntimeInstance)[
                                                                                RUNTIME_INSTANCE_INSTANCE_ID
                                                                                ]
                                                                            )
                                                                        }
                                                                    >
                                                                        <FaRegTrashAlt
                                                                            className={`${instances.length > _minInstantiation &&
                                                                                    !dataLoading
                                                                                    ? "sb3-text-black"
                                                                                    : "sb3-text-gray-light"
                                                                                }`}
                                                                        />
                                                                    </BorderlessButton>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </AccordionHeader>
                                                </div>
                                            }

                                            <AccordionBody
                                                {...provided.getBodyProps()}
                                                showOnClose={false}
                                                className={
                                                    _minInstantiation !== _maxInstantiation && isOpen
                                                        ? "sb3-bg-gray-100 sb3-border sb3-p-3"
                                                        : " sb3-py-3"
                                                }
                                            >
                                                <div className="sb3-flex-grow sb3-space-y-4 sb3-flex sb3-flex-col">
                                                    <div
                                                        className={`lg:sb3-flex lg:sb3-space-x-2 sb3-w-full sb3-flex-grow`}
                                                    >
                                                        {/* filter */}
                                                        {/* <AnimatePresence> */}
                                                        {!loading && isFilterActive && (
                                                            // !activeSubmodel || !specs[activeSubmodel.id] &&
                                                            <div
                                                                // initial={firstRender.current ? false : "initial"}
                                                                // animate="animate"
                                                                // exit = "exit"
                                                                className={`sb3-w-[300px] sb3-max-w-full sb3-border sb3-h-min ${platform !== THEME_PLATFORMS.BIG_COMMERCE ? "sb3-rounded sb3-px-2 sb3-pt-2" : "sb3-rounded-[20px] sb3-px-[14px] sb3-pt-[20px] sb3-pb-[24px] "}  sb3-hidden lg:sb3-block sb3-bg-white`}
                                                            // variants={firstRender.current ? {} : variants}
                                                            >
                                                                <p
                                                                    style={{
                                                                        color:
                                                                            builderSpec?.[BUILDER_CONFIG]?.[
                                                                            CONFIG_CUSTOMIZATION
                                                                            ]?.[CUSTOMIZATION_THEME_SETTINGS]?.[
                                                                            THEME_SUBTITLES
                                                                            ],
                                                                    }}
                                                                    className="sb3-font-semibold sb3-px-1.5"
                                                                >
                                                                    Filter this category
                                                                </p>

                                                                <Filters activeSubmodel={activeSubmodel}/>
                                                            </div>
                                                        )}
                                                        {/* </AnimatePresence> */}

                                                        {/* products */}
                                                        <div
                                                            className={`sb3-w-full sb3-transition-all sb3-space-y-2 sb3-flex sb3-flex-col ${platform !== THEME_PLATFORMS.BIG_COMMERCE &&
                                                                "sb3-bg-white"
                                                                } sb3-rounded`}
                                                        >
                                                            {isOpen ? (
                                                                <Submodel
                                                                    visibleFields={visibleFields}
                                                                    // visibleConnectors = {visibleConnectors}
                                                                    visibleCategories = {visibleCategories}
                                                                    instance={instance}
                                                                    model={model}
                                                                    uiModel={uiModel}
                                                                    filterField={(fieldB) =>
                                                                        fieldB[FRMDATA_PLACEMENT] ===
                                                                        FIELD_PLACEMENT.GUIDED_SELLING
                                                                    }
                                                                />
                                                            ) : (
                                                                <SubmodelSummary
                                                                    categoryIds={visibleCategories}
                                                                />
                                                            )}
                                                        </div>

                                                        {!activePage && !loading && (
                                                            <p>
                                                                Your Build is ready! Please click{" "}
                                                                <b>Add to Cart</b> to add items to the cart
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </AccordionBody>
                                        </div>
                                    )}
                                </AccordionLayout>
                            </div>
                        )}}
                    </PageModelIterator>
                }
                {
                     activePage && !activePage?.[PAGE_AUTO_ADVANCE] && 
                    <div className={`sb3-max-w-full sb3-m-auto sb3-border-t sb3-sticky sb3-flex sb3-justify-between sb3-bottom-0 sb3-left-0 ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? " sb3-p-5 sb3-rounded-2xl" : "sb3-bg-white sb3-py-2"}  sb3-w-full`}
                        style={{
                            backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                            builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]
                        }}
                    >
                        { 
                            activePageIndex !== 0 ?
                            <BorderedButton 
                                className="sb3-px-5 sb3-h-full !sb3-bg-gray "
                                onClick={handlePrevPage}
                                buttonStyle="primary"
                            >BACK</BorderedButton>
                            : <span></span>
                        }
                        <div className="sb3-max-w-4xl sb3-flex sb3-justify-end sb3-items-center sb3-space-x-2">
                            <BorderedButton 
                                className="sb3-px-5 sb3-h-full !sb3-bg-gray"
                                onClick={() => handleNextPage(true)}
                                buttonStyle="primary"
                            >SKIP</BorderedButton>

                            <FillButton
                                className="sb3-px-5 sb3-h-full"
                                onClick={() => handleNextPage(true)}
                                buttonStyle="primary"
                            >CONTINUE</FillButton>
                        </div>
                    </div>
                }
            </div>

            

            {/* right side */}
            {/* cart component */}
            {!loading && (
                <div className="sb3-w-full sb3-max-w-screen sb3-py-3 sb3-text-right sb3-space-y-2 lg:sb3-block sb3-hidden sb3-max-w-[300px]">
                    <div className={`
                        ${Utils.isLinear(theme) ? "sb3-top-0 sb3-min-h-screen sb3-max-h-screen sb3-h-min sb3-bg-gray " : "sb3-bg-white sb3-border sb3-top-[65px] sb3-max-h-[min(calc(100vh-_214px),_750px)] sb3-overflow-auto "}  
                        sb3-sticky sb3-text-left sb3-flex sb3-flex-col sb3-justify-between sb3-rounded-md sb3-min-w-[270px] sb3-min-h-[350px]
                        ${platform === THEME_PLATFORMS.BIG_COMMERCE && "sb3-rounded-[20px]"}
                    `}>
                        <Cart />
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfiguratorLayout;
