import { INFO_SIZES } from "constants/constants";
import { useEffect, useRef, useState } from "react";

/**
 * create a resizable component
 * 
 * @returns 
 */
const useResizable = (defaultSize: "small"|"medium"|"large"|"auto") => {
    const minWidth = 200, minHeight = 100, maxHeight = window.innerHeight - 100, maxWidth = 2000
    const [size, setSize] = useState<{width?: number, height?: number}>({ width: undefined, height: undefined });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isResizing, setIsResizing] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setSize(INFO_SIZES[defaultSize])
    }, [defaultSize])
  
    useEffect(() => {
      const handleMouseMove = (e: any) => {
        e.preventDefault()
        if (!isResizing || !ref.current) return;
        
        const rect = (ref.current as HTMLDivElement).getBoundingClientRect();
        
        const maxAllowedWidth = Math.min(maxWidth, window.innerWidth - rect.left);
        const maxAllowedHeight = Math.min(maxHeight, window.innerHeight - rect.top - 55);
        
        const newWidth = Math.max(minWidth, Math.min(e.clientX - rect.left, maxAllowedWidth, window.innerWidth));
        const newHeight = Math.max(minHeight, Math.min(e.clientY - rect.top, maxAllowedHeight, window.innerHeight));
        
        setSize({ width: newWidth + 60, height: newHeight + 30 });
  
        // Adjust position if tooltip extends beyond right or bottom edge
        const rightOverflow = (rect.left + newWidth) - window.innerWidth;
        const bottomOverflow = (rect.top + newHeight) - window.innerHeight;
        
        setPosition({
          x: rightOverflow > 0 ? -rightOverflow : 0,
          y: bottomOverflow > 0 ? -bottomOverflow : 0
        });
      };
  
      const handleMouseUp = () => {
        setIsResizing(false);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isResizing, minWidth, minHeight, maxWidth, maxHeight]);
  
    return { size, position, isResizing, setIsResizing, ref, maxWidth, maxHeight };
  };
  
export default useResizable  