import Utils from "Services/Utils"
import BorderedButton from "components/Button/BorderedButton"
import Header from "components/Header"
import { FRMDATA_CONFIG, FRMDATA_DISPLAY_NAME, FRMDATA_EXCLUSION, FRMDATA_SMID, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_MAX_INSTANTIATION, MODEL_MIN_INSTANTIATION, PAGE_FORM_DATA, PAGE_LAYOUT, PAGE_LAYOUTS, RUNTIME_INSTANCE_IS_EXCLUDED } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { Fragment, ReactNode, useMemo } from "react"
import { TModel, TRuntimeInstance, TUI_BuilderPage, TUI_BuilderSubmodel, TUI_Page } from "types"

type MyProps = {
    children: (params: {
        visibleFields: string[],
        visibleConnectors: string[],
        visibleCategories: string[],
        disableInstantiation: boolean,
        title: string|number|undefined,
        isNumbered: boolean,
        number: number | undefined,
        instances: TRuntimeInstance[],
        _minInstantiation: number,
        _maxInstantiation: number,
        isInstantiable: boolean,
        instance: TRuntimeInstance,
        model: TModel,
        uiModel: TUI_BuilderSubmodel,
    }) => ReactNode
}

const PageModelIterator = (props: MyProps) => {
    const {
        activePage,
        getInstances,
        specs,
        createInstance,
        dataLoading,
        platform
    } = useAppContext()

    return (
        <>
            {
                activePage?.[PAGE_FORM_DATA]?.map((uiModel, modelKey) => {
                    const model: TModel = specs[uiModel[FRMDATA_SMID]]
                    if(!model) {
                        // console.error("Model not found", "x2")
                        return <Fragment key = {modelKey}/>
                    }

                    const _minInstantiation = model[MODEL_DATA][MODEL_MIN_INSTANTIATION]
                    const _maxInstantiation = model[MODEL_DATA][MODEL_MAX_INSTANTIATION]

                    const isInstantiable = Utils.checkIfModelIsInstantiable(model)

                    const handleClick = () => {
                        createInstance(uiModel[FRMDATA_SMID])
                    }

                    // if _min = _max = 1 then the data will be in model spec instead of runtime spec
                    // const _instances = !isInstantiable ? [model[MODEL_DATA]] : getInstances(_model[MODEL_SUBMODEL_ID]) ?? []
                    const _instances = getInstances(uiModel[FRMDATA_SMID]) ?? []
                    const instanceCount = _instances.length

                    return _instances
                    .filter((instance) => !instance[RUNTIME_INSTANCE_IS_EXCLUDED])
                    .map((instance, key) => {
                        const disableInstantiation = _minInstantiation === _maxInstantiation

                        const {title, isNumbered} = Utils.getInstanceTitle(model, instance, isInstantiable, uiModel, platform)

                        const visibleNodes = NodeHelper.filterVisibleNodesUsingRuntime(
                            instance[MODEL_DYNAMIC_FIELDS], 
                            uiModel,
                            activePage?.[PAGE_LAYOUT]
                        )

                        return <Fragment key={key}>
                        {
                            ( activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && _minInstantiation !== _maxInstantiation) &&
                            <div className="sb3-flex sb3-justify-between sb3-items-center ">
                                <Header title={uiModel[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME] ?? ""}/>
                                <span className="sb3-flex sb3-items-center sb3-space-x-2">
                                    <span className="sb3-p-2 sb3-px-3 sb3-bg-gray-200 sb3-text-sm sb3-rounded-full">{instanceCount}</span>
                                    <BorderedButton onClick={handleClick} disabled = {(instanceCount ?? 0) >= _maxInstantiation || dataLoading} >Create</BorderedButton>
                                </span>
                            </div>  
                        }
                        {
                            props.children({
                                visibleFields: visibleNodes.visibleFieldIds,
                                visibleConnectors: visibleNodes.visibleConnectorIds,
                                visibleCategories: visibleNodes.visibleCategoryIds,
                                disableInstantiation,
                                title,
                                isNumbered,
                                number: key + 1,
                                instances: _instances,
                                _minInstantiation,
                                _maxInstantiation,
                                isInstantiable,
                                instance,
                                model,
                                uiModel
                            })
                        }</Fragment>
                    })
                })
                
            }
        </>
    )
}

export default PageModelIterator