import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { IoClose } from "react-icons/io5";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import { useAppContext } from 'contexts/AppContext';
import { BsTextareaResize } from 'react-icons/bs';

const MultiPopup = () => {
  const { popups, setPopups } = useAppContext();
  const [maxSize, setMaxSize] = useState({ width: 0, height: 0 });
  // const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const updateMaxSize = _.debounce(() => {
      const newMaxWidth = window.innerWidth - 25;
      const newMaxHeight = window.innerHeight - 25;
      
      setMaxSize({
        width: newMaxWidth,
        height: newMaxHeight
      });
  
      // Adjust popups that would be out of bounds after resize
      setPopups(prevPopups => prevPopups.map(popup => {
        if (popup.isMinimized) return popup;
  
        const newPopup = { ...popup };
  
        // Adjust width if it extends beyond the new window width
        if (popup.position.x + popup.size.width > newMaxWidth) {
          if (popup.size.width > newMaxWidth) {
            // If popup is wider than window, shrink it and move to x=0
            newPopup.size = {
              ...popup.size,
              width: newMaxWidth
            };
            newPopup.position = {
              ...popup.position,
              x: 0
            };
          } else {
            // Otherwise, just move it to fit
            newPopup.position = {
              ...popup.position,
              x: newMaxWidth - popup.size.width
            };
          }
        }
  
        // Adjust height if it extends beyond the new window height
        if (popup.position.y + popup.size.height > newMaxHeight) {
          if (popup.size.height > newMaxHeight) {
            // If popup is taller than window, shrink it and move to y=0
            newPopup.size = {
              ...newPopup.size,
              height: newMaxHeight
            };
            newPopup.position = {
              ...newPopup.position,
              y: 0
            };
          } else {
            // Otherwise, just move it to fit
            newPopup.position = {
              ...newPopup.position,
              y: newMaxHeight - popup.size.height
            };
          }
        }
  
        return newPopup;
      }));
    }, 150);
  
    updateMaxSize();
    window.addEventListener('resize', updateMaxSize);
    return () => {
      window.removeEventListener('resize', updateMaxSize);
      updateMaxSize.cancel();
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, popupId: number) => {
    if (e.button !== 0) return;
    
    // Update both local state and context
    setPopups(prevPopups => {
      const maxZ = _.maxBy(prevPopups, 'zIndex')?.zIndex ?? 0;
      const newPopups = prevPopups.map(popup => ({
        ...popup,
        zIndex: popup.id === popupId ? maxZ + 1 : popup.zIndex
      }));

      return newPopups;
    });
  };

  
  const handleDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, popupId: number) => {
    e.preventDefault();
    
    const popup = _.find(popups, ['id', popupId]);
    if (!popup) return;

    const startX = e.clientX - popup.position.x;
    const startY = e.clientY - popup.position.y;

    const handleMouseMove = _.throttle((e) => {
      setPopups(prevPopups => prevPopups.map(popup => {
        if (popup.id !== popupId) return popup;

        const newX = _.clamp(e.clientX - startX, 0, maxSize.width - popup.size.width);
        const newY = _.clamp(e.clientY - startY, 0, maxSize.height - (popup.isMinimized ? 40 : popup.size.height));

        return {
          ...popup,
          position: { x: newX, y: newY }
        };
      }));
    }, 16);

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      handleMouseMove.cancel();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleResize = (e: React.MouseEvent<SVGElement, MouseEvent>, popupId: number) => {
    e.preventDefault();
    const popup = _.find(popups, ['id', popupId]);
    if (!popup || popup.isMinimized) return;

    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = popup.size.width;
    const startHeight = popup.size.height;

    const handleMouseMove = _.throttle((e) => {
      setPopups(prevPopups => prevPopups.map(popup => {
        if (popup.id !== popupId) return popup;

        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        const newWidth = _.clamp(startWidth + deltaX, 200, maxSize.width - popup.position.x);
        const newHeight = _.clamp(startHeight + deltaY, 100, maxSize.height - popup.position.y);

        return {
          ...popup,
          size: { width: Math.min(window.innerWidth * 0.95, newWidth), height: Math.min(window.innerHeight * 0.95, newHeight) },
          prevHeight: newHeight
        };
      }));
    }, 16);

    const handleMouseUp = () => {
      setPopups(prevPopups => prevPopups.map(popup => ({
        ...popup,
        isResizing: false
      })));
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      handleMouseMove.cancel();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    setPopups(prevPopups => prevPopups.map(popup => ({
      ...popup,
      isResizing: popup.id === popupId
    })));
  };

  const toggleMinimize = (popupId: number) => {
    setPopups(prevPopups => prevPopups.map(popup => {
      if (popup.id !== popupId) return popup;
      
      return {
        ...popup,
        isMinimized: !popup.isMinimized,
        size: {
          ...popup.size,
          height: !popup.isMinimized ? 40 : popup.prevHeight
        }
      };
    }));
  };


  const closePopup = (popupId: number) => {
    // Remove from local state
    // setPopups(prevPopups => _.reject(prevPopups, ['id', popupId]));
    
    // Remove from context and reindex remaining messages
    setPopups(prevMessages => {
      const index = prevMessages.findIndex(popup => popup.id === popupId);
      const newMessages = [...prevMessages];
      newMessages.splice(index, 1);
      return newMessages;
    });
  };

  return (
    <div className="sb3-fixed sb3-inset-0 sb3-pointer-events-none sb3-z-[5000]">
      {popups
      .filter(popup => !popup.isMinimized)
      .map((popup, key) => (
        <div
          key={popup.id}
          className="sb3-absolute sb3-bg-white sb3-rounded-lg sb3-shadow-lg sb3-pointer-events-auto sb3-flex sb3-flex-col"
          style={{
            left: popup.position.x,
            top: popup.position.y,
            width: popup.size.width,
            height: popup.size.height,
            zIndex: popup.zIndex,
            maxHeight: "95%",
            maxWidth: "95%",
          }}
          onMouseDown={(e) => handleMouseDown(e, popup.id)}
        >
          {/* Header */}
          <div
            className="sb3-flex sb3-justify-between sb3-items-center sb3-px-4 sb3-py-2 sb3-bg-gray-100 sb3-rounded-t-lg sb3-cursor-move"
            onMouseDown={(e) => handleDragStart(e, popup.id)}
          >
            <span className="sb3-font-medium sb3-select-none">
              {popup.title ?? `Info ${popup.id}`}
            </span>
            <div className="sb3-flex sb3-items-center sb3-gap-1">
              <button
                className="sb3-p-1 hover:sb3-bg-gray-200 sb3-rounded focus:!sb3-outline-none"
                onClick={() => toggleMinimize(popup.id)}
              >
                {popup.isMinimized ? <IoCaretUp className="sb3-w-4 sb3-h-4" /> : <IoCaretDown className="sb3-w-4 sb3-h-4" />}
              </button>
              <button
                className="sb3-p-1 hover:sb3-bg-gray-200 sb3-rounded focus:!sb3-outline-none"
                onClick={() => closePopup(popup.id)}
              >
                <IoClose className="sb3-w-4 sb3-h-4" />
              </button>
            </div>
          </div>

          {/* Content */}
          <div 
            className={`sb3-overflow-auto sb3-flex-grow ${popup.isMinimized ? 'sb3-h-0' : ''}`} 
            style={{ height: popup.isMinimized ? 0 : 'calc(100% - 4rem)' }}
          >
            <div className="react-tooltip info-text sb3-p-4" dangerouslySetInnerHTML={{ __html: popup.message }} />
          </div>

          {/* Resize handle */}
          {!popup.isMinimized && (
            <BsTextareaResize
              className="sb3-absolute sb3-bottom-0 sb3-right-0 sb3-w-4 sb3-h-4 sb3-cursor-se-resize"
              onMouseDown={(e) => handleResize(e, popup.id)}
            />
          )}
        </div>
      ))}
      {/* Minimized popups container */}
      <div className="sb3-fixed sb3-bottom-0 sb3-left-0 sb3-flex sb3-gap-2 sb3-p-2 sb3-pointer-events-auto sb3-max-w-full sb3-overflow-auto">
        {popups.filter(popup => popup.isMinimized).map((popup) => (
          <div
            key={popup.id}
            className="sb3-bg-white sb3-rounded-lg sb3-shadow-lg sb3-w-[200px] sb3-border sb3-h-10 sb3-flex sb3-items-center sb3-justify-between sb3-px-4"
            style={{ zIndex: popup.zIndex }}
            onMouseDown={(e) => handleMouseDown(e, popup.id)}
          >
            <span className="sb3-font-medium sb3-select-none sb3-truncate sb3-max-w-[100px]">
              {popup.title ?? `Info ${popup.id}`}
            </span>
            <div className="sb3-flex sb3-items-center sb3-gap-1">
              <button
                className="sb3-p-1 hover:sb3-bg-gray-200 sb3-rounded  focus:!sb3-outline-none"
                onClick={() => toggleMinimize(popup.id)}
              >
                <IoCaretUp className="sb3-w-4 sb3-h-4" />
              </button>
              <button
                className="sb3-p-1 hover:sb3-bg-gray-200 sb3-rounded  focus:!sb3-outline-none"
                onClick={() => closePopup(popup.id)}
              >
                <IoClose className="sb3-w-4 sb3-h-4" />
              </button>
            </div>
          </div>
        ))}
      </div>  
    </div>
  );
};

export default MultiPopup;