import BorderedButton from "components/Button/BorderedButton"
import * as consts from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import React, { useEffect} from "react"
import { FaCheck, FaCheckCircle } from "react-icons/fa"
import Utils from "Services/Utils"
import { TUI_BuilderPage } from "types"

/**
 * Pages navbar build from builder spec
 * will group pages based on pageLabels
 * 
 * @returns 
 */

export default function PagesNavbar() {
    const {
        theme,
        // uiDefn,
        activePage,
        setPage,
        getInstances,
        builderSpec,
        specs,
        platform
    } = useAppContext()

    useEffect(()=> {
        document.querySelector(`#step-submodel-${activePage?.[consts.PAGE_ID]}`)?.scrollIntoView({ block: 'center',  behavior: 'smooth' })
    }, [activePage])
    
    // if its applico theme hide pages if submodel is not instantiated
    const newPages = theme === consts.THEMES.APPLICO ? (builderSpec?.[consts.BUILDER_PAGES].filter((page, key) => {
        let isPageVisible = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[consts.FRMDATA_SMID]) ?? []
            return _instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
        })

        return isPageVisible
    }) ?? []) : (builderSpec?.[consts.BUILDER_PAGES] ?? [])

    // group pages by label
    const pageGroups = newPages?.reduce((acc: {[x: string]: TUI_BuilderPage[]}, page) => {
        const key = !!page[consts.PAGE_LABEL] ? page[consts.PAGE_LABEL]: "Other"
        const alLeastOneSubmodelInstance = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
            return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
        })

        if(alLeastOneSubmodelInstance) {
            if(!acc[key]) acc[key] = []
            acc[key].push(page)
        }
        
        return acc
    },{}) ?? {}

    if(Object.entries(pageGroups).length === 0) return <></>

    return (
        <div className="submodelNav sb3-flex lg:sb3-space-y-0 sb3-max-w-full sb3-overflow-auto">
    {
        Object.entries(pageGroups).map(([groupName, pages], index) => {
           return  <React.Fragment key={index}>
            <div 
            className="sb3-space-y-2" 
            >
                { (Object.keys(pageGroups).some((groupName => groupName!== "Other"))) && <p className="sb3-text-sm sb3-text-gray-extraDark">{groupName}</p> }

                <div className={`sb3-flex sb3-items-center  ${(isArray(pages) && pages?.length > 0) && 'sb3-last:pr-1'}`}>
                    {
                        pages?.map((page, key) => {
                            // check if any of the submodel is required
                            const isRequired = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                                const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                                if(!modelSubmodel) return false

                                const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                                if(instances?.length === 0) return false
                                return instances?.some(instance => NodeHelper.checkIfSubmodelIsRequiredByRuntimeSpec(
                                    instance
                                ))
                            })

                            const isDone = page[consts.PAGE_FORM_DATA].every((builderSubmodel) => {
                                const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                                if(!modelSubmodel) return true

                                const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                                if(instances?.length === 0) return true

                                return instances?.every(instance => NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
                                    instance, 
                                    page[consts.PAGE_LAYOUT]
                                ))
                            })
                            
                            return (
                                <div 
                                    key={key}
                                    id={`step-submodel-${page?.[consts.PAGE_ID]}`}
                                    className={`sb3-pr-2`}>
                                        <BorderedButton 
                                            active={activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]}
                                            onClick={() => setPage(page[consts.PAGE_ID])}
                                            className="sb3-space-x-2 sb3-w-max sb3-font-semibold sb3-text-black !sb3-justify-center"
                                            style={{backgroundColor:builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_SECONDARY] && platform === consts.THEME_PLATFORMS.BIG_COMMERCE && 
                                                builderSpec[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_THEME_SETTINGS][consts.THEME_SECONDARY]}}
                                            >
                                            { isRequired && isDone && <FaCheckCircle style={{
                                                color: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON],
                                                fill: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON]
                                            }} className={`sb3-text-base ${activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "sb3-text-white" : ""}`}/>}
                                            <span style={{
                                                color: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON]
                                            }} className="sb3-text-center">{page[consts.PAGE_NAME]} {(isRequired && !isDone) ? <span className="sb3-text-red-500">*</span> : ""}</span>
                                        </BorderedButton>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <span className="sb3-pr-2"/>
            </React.Fragment>
        })
        
    }
</div>
    )
}
