import React from "react";
import PropTypes from "prop-types";
import { TButtonProps } from "types/components/Button";
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_ONLINE_BUTTON } from "constants/constants";
import { useAppContext } from "contexts/AppContext";

const BorderlessButton = ({
  type = "button",
  id = "",
  onClick,
  disabled = false,
  disabledClassName = "",
  className="",
  buttonStyle,
  children,
  color,
}: TButtonProps) => {
  const {builderSpec} = useAppContext()

  let themeClasses = "hover:!sb3-text-primary-hover sb3-text-primary disabled:sb3-text-primary-disabled"
  const styles: {[x: string]: string|undefined} = {}

  if(buttonStyle === "danger") 
    themeClasses = `hover:!sb3-text-danger-hover sb3-text-danger disabled:sb3-text-danger-disabled`
  else {
    styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_ONLINE_BUTTON]
  }

  return (
    <div>
      <button
        type={type}
        className={`sb3-bg-transparent sb3-border  sb3-font-normal !sb3-border-transparent ${disabledClassName} ${className} ${themeClasses}
           disabled:sb3-text-gray-dark disabled:sb3-cursor-default
          sb3-p-2 sb3-py-1 sb3-font-medium sb3-leading-5 sb3-min-h-[36px] sb3-m-0 sb3-min-w-[92px]
          sb3-cursor-pointer sb3-inline-block sb3-font-noto-sans sb3-text-sm
          hover:!sb3-bg-transparent hover:!sb3-border-transparent focus:!sb3-bg-transparent focus:!sb3-border-transparent focus:!sb3-outline-none
          `}
        id={id}
        onClick={onClick}
        disabled={disabled}
        style={styles}
      >
        {children}
      </button>
    </div>
  );
};

export default BorderlessButton;