import AccordionBody from "components/Accordion/AccordionBody"
import AccordionHeader from "components/Accordion/AccordionHeader"
import AccordionLayout from "components/Accordion/AccordionLayout"
import BorderedButton from "components/Button/BorderedButton"
import BorderlessButton from "components/Button/BorderlessButton"
import FillButton from "components/Button/FillButton"
import Cart from "components/Cart"
import SubmodelSummary from "components/Cart/SubmodelSummary"
import Header from "components/Header"
import Title from "components/Header/Title"
import AvailabilityInput from "components/Input/AvailabilityInput"
import CustomCompass from "components/Input/CompassInput"
import PageModelIterator from "components/Iterators/PageModelIterator"
// import LinearPreview from "components/LinearPreview"
import Submodel from "components/Submodel"
import { BUILDER_CONFIG, BUILDER_PAGES, CONFIG_CUSTOMIZATION, CUSTOMIZATION_NAVIGATION, CUSTOMIZATION_THEME_SETTINGS, DEFN_BUILDER_PAGES, FIELD_ID, FIELD_NAME, FIELD_TYPE, FIELD_TYPES, FRMDATA_SMID, MODEL_DATA, MODEL_DYNAMIC_FIELDS, PAGE_AUTO_ADVANCE, PAGE_FORM_DATA, PAGE_ID, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_IS_EXCLUDED, THEME_BACKGROUND, THEME_PLATFORMS, THEME_SECONDARY, ZIP_CODE_FIELD } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { FaRegTrashAlt } from "react-icons/fa"
import { GrMapLocation } from "react-icons/gr"
import { toast } from "react-toastify"
import Utils from "Services/Utils"
import { TRuntimeInstance} from "types"

const GuidedSellingLayout = () => {
    const {
        dataLoading,
        deleteInstance,
        activePage,
        theme,
        // specs,
        builderSpec,
        platform,
        categoryProductsCount,
        activePageIndex,
        handleNextPage,
        handlePrevPage,
        ifAnyVisibleCategory,
        selectedValues
    } = useAppContext()


    // const isPageRequired = activePage?.[PAGE_FORM_DATA].some((builderSubmodel) => {
    //     const modelSubmodel = specs[builderSubmodel[FRMDATA_SMID]]
    //     if(!modelSubmodel) return false

    //     const instances = getInstances(builderSubmodel[FRMDATA_SMID])
    //     if(instances?.length === 0) return false

    //     return instances?.some(instance => NodeHelper.checkIfSubmodelIsRequiredByRuntimeSpec(instance))
    // })


    const findZipCode = () => {
        const zipCodeKey = selectedValues && Object.keys(selectedValues).find(key => key.includes(ZIP_CODE_FIELD));
        return zipCodeKey ? selectedValues?.[zipCodeKey][0] : null;
    };
    // const zipcode = findZipCode()


    return (
        <div className="sb3-flex sb3-justify-between sb3-gap-4">
            <div className={`sb3-space-y-2 sb3-w-full ${((Utils.isLinear(theme) || (ifAnyVisibleCategory && Utils.isConfigurator(theme)))) ? "sb3-max-w-5xl" : "sb3-max-w-7xl"}`}>
                {
                    Utils.checkIfClientWebsite() && ifAnyVisibleCategory &&
                    <div className="sb3-w-full sb3-flex sb3-justify-end sb3-px-7"><AvailabilityInput/></div>
                }

               
                <PageModelIterator>
                    {({
                        visibleFields,
                        // visibleConnectors,
                        visibleCategories,
                        disableInstantiation,
                        title,
                        isNumbered,
                        number,
                        instances,
                        _minInstantiation,
                        _maxInstantiation,
                        isInstantiable,
                        instance,
                        model,
                        uiModel
                    }) => (
                            <div key={number} className={`sb3-space-y-1  sb3-rounded-none sb3-pb-5 ${Utils.isConfigurator(theme) ? `sb3-px-3` : "sb3-border-b sb3-border-gray sb3-last:border-0"}`}>
                                <AccordionLayout disabled={disableInstantiation} initialState={true}>
                                    {({isOpen, ...provided}) => (
                                    <>
                                        {(!!title || uiModel.items.some((item) => item[FIELD_TYPE] === FIELD_TYPES.CATEGORY) || isInstantiable) && <div className={`
                                            ${Utils.isLinear(theme) ? "sb3-top-0" : " sb3-top-[56px]"}
                                            sb3-sticky sb3-bg-white sb3-z-10  sb3-border-b`}
                                            style={{backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_BACKGROUND]}}
                                            >
                                            <AccordionHeader {...provided.getHeaderProps()}>
                                                <span className="sb3-flex sb3-justify-between sb3-items-center">
                                                    {title ? (
                                                        <Header
                                                            title={title}
                                                            number={isNumbered ? number : undefined}
                                                            className={
                                                                !Utils.isConfigurator(theme) ? "!sb3-text-xl" : "!sb3-text-xl"
                                                            }
                                                        />
                                                    ) : <span></span>}
                                                    <div className="sb3-flex sb3-gap-x-2 sb3-items-right sb3-flex-wrap sb3-justify-end sb3-min-h-9 sb3-items-center">
                                                
                                                        {
                                                            categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]] 
                                                            && Object.entries(categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]])
                                                            .sort(([catId1, ], [catId2, ]) => uiModel.items.findIndex(item => item.id === catId1) - uiModel.items.findIndex(item => item.id === catId2))
                                                            .map(([catId, productCount], key) => {
                                                                return <span key={key}>
                                                                    <span className="">{model.data.productCategories.find(_category => _category[FIELD_ID] === catId)?.[MODEL_DATA][FIELD_NAME] }</span>: {productCount > 50 ? "50+" : productCount} Compatible Selections
                                                                </span>
                                                            })
                                                        }
                                                    
                                                        
                                                    </div>
                                                    {
                                                        (!disableInstantiation && isInstantiable) && 
                                                        <BorderlessButton 
                                                        className="sb3-min-w-min"
                                                        disabled = {instances.length <= _minInstantiation || dataLoading}
                                                        onClick = {() => deleteInstance((instance as TRuntimeInstance)[RUNTIME_INSTANCE_INSTANCE_ID])}
                                                        >
                                                            <FaRegTrashAlt className={`${(instances.length > _minInstantiation && !dataLoading) ? "sb3-text-black" :  "sb3-text-gray-light"}`}/>
                                                        </BorderlessButton>
                                                    }
                                                </span>
                                            </AccordionHeader>
                                        </div>}
                                    
                                        <AccordionBody {...provided.getBodyProps()} 
                                            showOnClose={true}
                                            className={(_minInstantiation !== _maxInstantiation && isOpen) ? "sb3-bg-gray-100 sb3-border sb3-p-3" : "sb3-py-3"}>
                                            {
                                                isOpen ?
                                                <Submodel 
                                                    visibleFields = {visibleFields} 
                                                    // visibleConnectors = {visibleConnectors} 
                                                    visibleCategories = {visibleCategories} 
                                                    instance = {instance} 
                                                    model={model} 
                                                    uiModel={uiModel}
                                                />
                                                :
                                                <SubmodelSummary categoryIds = {visibleCategories} />
                                            }
                                        </AccordionBody> 
                                    </>
                                    )}
                                </AccordionLayout>
                            </div>
                    )}
                </PageModelIterator>
                {
                    activePage && !activePage?.[PAGE_AUTO_ADVANCE] && 
                    <div className={`sb3-max-w-full sb3-m-auto sb3-border-t sb3-sticky sb3-flex sb3-justify-between sb3-bottom-0 sb3-left-0 ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? " sb3-p-5 sb3-rounded-2xl" : "sb3-bg-white sb3-py-2"}  sb3-w-full`}
                        style={{
                            backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                            builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]
                        }}
                    >
                        { 
                            activePageIndex !== 0 ?
                            <BorderedButton 
                                className="sb3-px-5 sb3-h-full !sb3-bg-gray "
                                onClick={handlePrevPage}
                                buttonStyle="primary"
                            >BACK</BorderedButton>
                            : <span></span>
                        }
                        <div className="sb3-max-w-4xl sb3-flex sb3-justify-end sb3-items-center sb3-space-x-2">
                            <BorderedButton 
                                className="sb3-px-5 sb3-h-full !sb3-bg-gray"
                                onClick={() => handleNextPage(true)}
                                buttonStyle="primary"
                            >SKIP</BorderedButton>

                            <FillButton 
                                className="sb3-px-5 sb3-h-full"
                                onClick={() => handleNextPage(true)}
                                buttonStyle="primary"
                            >CONTINUE</FillButton>
                        </div>
                    </div>
                }
            </div>

            {
                (Utils.isLinear(theme) || (ifAnyVisibleCategory && Utils.isConfigurator(theme))) && 
                <div className={`${Utils.isLinear(theme) ? 'sb3-bg-gray sb3-px-3' : ''} sb3-max-w-[300px] sb3-py-3 sb3-max-h-full  lg:sb3-block sb3-hidden`}>
                    <div className={`
                        ${Utils.isLinear(theme) ? "sb3-top-0 sb3-min-h-screen sb3-max-h-screen sb3-h-min sb3-bg-gray " : "sb3-bg-white sb3-border sb3-top-[65px] sb3-max-h-[min(calc(100vh-_214px),_750px)] sb3-overflow-auto "}  
                        sb3-sticky sb3-text-left sb3-flex sb3-flex-col sb3-justify-between sb3-rounded-md sb3-min-w-[270px] sb3-min-h-[350px]
                        ${platform === THEME_PLATFORMS.BIG_COMMERCE && "sb3-rounded-[20px]"}
                    `}>
                        <Cart />
                    </div>
                </div>
            }
            
        </div>
    )
}

export default GuidedSellingLayout