import BorderedButton from "components/Button/BorderedButton"
import SB2Sort from "components/Input/SB2Sort"
import { BUILDER_CONFIG, CATEGORY_ID, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FIELD_ID, FIELD_PLACEMENT, FRMDATA_ITEMS, FRMDATA_PLACEMENT, MODEL_CATEGORIES, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_NAME, RUNTIME_INSTANCE_FIELD_VALUE, RUNTIME_INSTANCE_IS_INPUT, RUNTIME_INSTANCE_MAX_COUNT, RUNTIME_INSTANCE_MAX_QUANTITY, THEME_SECONDARY, THEME_TITLE } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import { Dispatch, SetStateAction } from "react"
import Utils from "Services/Utils"
import { TModel, TProductCategoryField, TRuntimeInstance, TUI_BuilderSubmodel, TUI_Node } from "types"
import { TCartItem } from "types/Cart"
import { TActiveSubmodel } from "types/contexts/AppContext"
import { LuFilter } from "react-icons/lu";

type MyProps = {
    title?: string
    selectedProducts: TCartItem[] | undefined
    setFilterOpen: Dispatch<SetStateAction<TActiveSubmodel|undefined>>
    productCount: number,
    submodelName?: string,
    isLoading?: boolean,
    categorySort?: string
    setCategorySort?: Dispatch<SetStateAction<string>>
    model: TModel
    uiModel: TUI_BuilderSubmodel
    instance: TRuntimeInstance
}

const CategoryHeader = ({
    selectedProducts,
    setFilterOpen,
    productCount,
    submodelName,
    isLoading,
    categorySort,
    setCategorySort,
    title,
    model,
    uiModel,
    instance
}: MyProps) => {
    const {
        builderSpec,
        // activeSubmodel,
        specs,
        activePage,
    } = useAppContext()

    const activeSubmodel: TActiveSubmodel = {
        ...model,
        instance: instance,
        uiModel: uiModel
    }

    const visibleNodes = activeSubmodel && NodeHelper.filterVisibleNodesUsingRuntime(
        activeSubmodel.instance[MODEL_DYNAMIC_FIELDS],
        activeSubmodel.uiModel
    )

    const isFilterActive = activeSubmodel &&
        activeSubmodel.uiModel?.[FRMDATA_ITEMS]
            .filter((fieldB: TUI_Node) => {
                const fieldR = activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

                return fieldB[FRMDATA_PLACEMENT] !== FIELD_PLACEMENT.GUIDED_SELLING
                    && fieldR && visibleNodes.visibleFieldIds.includes(fieldB[FIELD_ID])
                    && fieldR[RUNTIME_INSTANCE_IS_INPUT]
            }).length > 0 &&
        isArray(visibleNodes?.visibleFieldIds) &&
        (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][
            MODEL_FIELDS
        ].filter((field) => visibleNodes.visibleFieldIds.includes(field[FIELD_ID]))
            .length > 0
        && isArray(visibleNodes?.visibleFieldIds)
        && (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][MODEL_FIELDS]
            .filter((field) => visibleNodes?.visibleFieldIds.includes(field[FIELD_ID])).length > 0
        && specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][MODEL_CATEGORIES]
            ?.some((cat: TProductCategoryField) => (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1)
                > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) && (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]))

    const filters = (activeSubmodel?.instance?.[MODEL_DYNAMIC_FIELDS] && Object.entries(activeSubmodel?.instance[MODEL_DYNAMIC_FIELDS])?.filter(([fieldId, field]) => {
        return field.type !== "Category" && visibleNodes?.visibleFieldIds?.includes(fieldId) && isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) && field[RUNTIME_INSTANCE_FIELD_VALUE].length > 0
    })) ?? []

    return (
        <div className="sb3-space-y-4 sb3-max-w-full">

            <div className="sb3-flex sb3-flex-wrap xl:sb3-flex-nowrap lg:sb3-flex-row sb3-flex-col sb3-w-full lg:sb3-justify-between sb3-gap-3">
                <div className="sb3-flex-grow">
                    {/* <div className="sb3-flex sb3-justify-between"> */}
                        <h4 style={{ color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_TITLE] }} className="sb3-flex sb3-space-x-2 sb3-font-bold sb3-text-lg">
                            {title ?? activePage?.[PAGE_NAME]}
                        </h4>

                        {/* <h4 style={{ color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES] }} className="sb3-flex sb3-space-x-2 sb3-items-center sb3-w-max sb3-font-bold sb3-text-lg">
                            <span>{isLoading ? <SkeletonElement type={"title !sb3-w-9 sb3-pr-2"} /> : (productCount > 50 ? "50+" : productCount)}</span> <span>Compatible Selection{productCount > 1 ? 's' : ""}</span>
                            <span className="sb3-text-xs sb3-text-gray-extraDark">({isLoading ? <SkeletonElement type={"text-25 sb3-inline sb3-pr-2"} /> : (productCount > 50 ? "50+" : productCount)} options)</span>
                        </h4> */}
                    {/* </div> */}
                    <p>Compatibility based on selections in your build</p>
                </div>

                <div className="sb3-flex lg:sb3-justify-end sb3-justify-between sb3-space-x-2 lg:sb3-space-x-0">
                    {
                        isFilterActive &&
                        <div className="sb3-w-1/2 lg:sb3-hidden">
                            <BorderedButton
                            style={{backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY]}}
                            onClick={() => setFilterOpen(activeSubmodel)} className="!sb3-min-w-full !sb3-text-black !sb3-border-gray-400 !sb3-border">
                                <span className="sb3-flex sb3-items-center sb3-justify-start sb3-space-x-2">
                                    <LuFilter className="sb3-fill-none sb3-text-lg"/><span>Filters {filters.length > 0 && `(${filters.length})`}</span>
                                </span>
                            </BorderedButton>
                        </div>
                    }

                    <div className="sb3-flex lg:sb3-hidden sb3-items-center sb3-w-1/2 lg:sb3-w-full lg:sb3-space-y-1">
                        {/* <label className="sb3-text-sm lg:sb3-block sb3-hidden sb3-ml-0 sb3-font-bold lg:sb3-m-auto">Sort By:</label> */}
                        <SB2Sort
                            setCategorySort={setCategorySort}
                            categorySort={categorySort}
                        />
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CategoryHeader