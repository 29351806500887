import Select from "components/Input/Select"
import {  ADD_DESCRIPTION, ADD_INFO, CHOICE_ID, CHOICE_IS_DISABLED, CHOICE_IS_SELECTED, CHOICE_NAME, CONF_DECIMAL_CONVERSION, CONF_DECIMAL_MAX, CONF_DECIMAL_MIN, CONF_DEFAUT_VALUE, CONF_INCREMENT, CONF_LABEL_FRACTION, CONF_LABEL_WHOLE_NUMBER, CONF_MAX, CONF_MAX_CHAR_COUNT, CONF_MIN, CONF_PROP_TYPE, CONF_SELECT_TYPE, CONF_SVG, CONF_UNITS, CONF_VALUE, FIELD_ADDITIONAL_DATA, FIELD_DATA_CONFIGURATION, FIELD_ID, FIELD_NAME, FIELD_TYPE, FIELD_TYPES, FRMDATA_DISPLAY_NAME, FRMDATA_FIELD_NAME, FRMDATA_ID, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUB_MODEL, MODEL_SUBMODEL_ID, PAGE_LAYOUT, PAGE_LAYOUTS, RUNTIME_INSTANCE_CHOICES, RUNTIME_INSTANCE_FIELD_VALUE, RUNTIME_INSTANCE_INSTANCE_ID, SELECT_TYPE, THEMES, UI_FIELD_TYPES, VALUE_TO_INSTANTIATION_MAP_APPLICO, VALUE_TO_INSTANTIATION_MAP_CUMMINS } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import { TChoice, TModel, TRuntimeInstance, TUI_BuilderSubmodel, TUI_Node } from "types"
import TextField from "components/Input/TextField"
import NumberField from "components/Input/NumberField"
import ToggleField from "components/Input/ToggleField"
import FractionalField from "components/Input/FractionalField"
import MultiChoiceGrid from "components/Input/MultiChoiceGrid"
import Utils from "Services/Utils"
import SmallGridChoice from "components/Input/SmallGridChoice"
import _, { debounce, isArray, toLower } from "lodash"
import SearchBar from "components/Input/SearchBar"
import { useCallback, useEffect, useState } from "react"
import CheckBoxes from "components/Input/Checkboxes"
import NodeHelper from "helpers/NodeHelper"
import RadioButtons from "components/Input/RadioButtons"
import Title from "components/Header/Title"

type MyProps = {
    fieldB: TUI_Node,
    instance: TRuntimeInstance
    required?: boolean
    isFilter?: boolean
    limit?: number
    isSearchEnabled?: boolean
    showType?: string,
    submodel: TModel
    uiModel: TUI_BuilderSubmodel
}

/**
 * To render fields according to the type of field in model spec 
 * and UI defn
 * 
 * @param {TField} fieldB - field data
 * @param {TUI_Field} ui_field - UI definition of field
 * @returns 
 * 
 * @todo 
 * - add ui definition
 */
const Field = ({
    fieldB, 
    instance, 
    required, 
    isFilter,
    limit,
    isSearchEnabled,
    showType,
    submodel,
    uiModel
}: MyProps) => {
    const {
        selectedValues,
        setSelectedValues,
        updateInstanceInput,
        dataLoading,
        loading,
        sort,
        disableClick,
        theme,
        setActiveSubmodel,
        activePage,
        runtimeSpec
    } = useAppContext()

    const [choicesSearchTerm, setChoicesSearchTerm] = useState<string>("")
    const [value, setValue] = useState<(string | number) | (string | number)[] | TChoice[] | undefined>([])

    useEffect(() => {
        if(!instance) return 
        
        setValue(NodeHelper.getFieldValueByFieldR(instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]))
    }, [runtimeSpec, instance])

    useEffect(() => {
        setChoicesSearchTerm("")
    }, [activePage])


    const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

    const fieldKey = NodeHelper.getFieldKey(fieldB[FIELD_ID], instance)
    const fieldM = submodel[MODEL_DATA][MODEL_FIELDS].find((field) => field[FIELD_ID] === fieldB[FRMDATA_ID])
    
    /** handle changes for normal inputs */
    const setInput = (item?: string|boolean|number) => {
        // const config = builderConfig()?.compatibleProducts
        // CompatioAnalytics.track(
        //     CompatioAnalytics.clicks.fieldSelect,
        //     {
        //         baseFinalProductID: config?.productSku,
        //         baseParentProductID: config?.productSku,
        //         baseFinalProductName: config?.productName,
        //         submodelId: submodel[MODEL_SUBMODEL_ID],
        //         submodelName: submodel[MODEL_DATA][MODEL_SUB_MODEL],
        //         fieldId: field[FIELD_ID],
        //         fieldName: field[FIELD_NAME],
        //         fieldValue: item,
        //         click_action: CompatioAnalytics.clicks.fieldSelect,
        //     },
        //     'SmartBuilder3',
        //     undefined,
        //     'V3'
        // );
        setSelectedValues((prev) => {
            const nextValue = {...prev}

            if(fieldB[FIELD_ID]) nextValue[fieldKey] = item !== undefined ? [item] : []
               
            return nextValue
        })  

        updateInstanceInput(fieldB[FIELD_ID], item !== undefined ? [item] : [], instance?.[RUNTIME_INSTANCE_INSTANCE_ID], false, false, item === "")
    }

    const handleInputChange = useCallback(debounce(setInput, 600), []);

    if(!fieldB) return <></>

    /** handle inputs for multiselect inputs */
    const handleValueSelect = async (value?: string[]|string) => {
        if(!fieldB) return

        setActiveSubmodel({...submodel, instance: instance, uiModel: uiModel})
        // if(!instance) {
        //     createInstance(
        //     submodel[MODEL_SUBMODEL_ID], (
        //         (submodel[MODEL_DATA][MODEL_FIELDS].find(_field => _field[FIELD_ID] === field[FIELD_ID]) as TField)
        //         [FIELD_DATA_CONFIGURATION][CONF_CHOICES] as TChoice[]).map(choice => choice[CHOICE_NAME]
        //     ))
        //     return
        // }
        // const config = builderConfig()?.compatibleProducts

        // CompatioAnalytics.track(
        //     CompatioAnalytics.clicks.fieldSelect,
        //     {
        //         baseFinalProductID: config?.productSku,
        //         baseParentProductID: config?.productSku,
        //         baseFinalProductName: config?.productName,
        //         submodelId: submodel[MODEL_SUBMODEL_ID],
        //         submodelName: submodel[MODEL_DATA][MODEL_SUB_MODEL],
        //         fieldId: field[FIELD_ID],
        //         fieldName: field[FIELD_NAME],
        //         fieldValue: value,
        //         click_action: CompatioAnalytics.clicks.fieldSelect,
        //     },
        //     'SmartBuilder3',
        //     undefined,
        //     'V3'
        // );

        // get all choices based on values
        let selectedChoices: TChoice[] = []
        if(_.isArray(value))
            // if multi value selection
            selectedChoices = fieldR[RUNTIME_INSTANCE_CHOICES]?.filter((choice) => value.includes(choice[CHOICE_ID])) ?? []
        else{
            // if single value
            const selectedChoice = fieldR[RUNTIME_INSTANCE_CHOICES]?.find((choice) => choice[CHOICE_ID] === value)
            if(selectedChoice) selectedChoices = [selectedChoice]
        }

        // if(selectedChoices.length < 1) return toast.error("Value Error")

        const isAnyChoiceDisabled = selectedChoices.some((item) => item[CHOICE_IS_DISABLED])
        
        // find difference in values
        const prevValues = NodeHelper.getFieldValueByFieldR(fieldR) as string[] ?? []
        
        let valueDifferences: TChoice[] = []
        let _toSelect = false

        if(isArray(prevValues) && selectedChoices.length > prevValues.length) {
            valueDifferences = selectedChoices.filter((choice) => !prevValues.includes(choice[CHOICE_ID]))
            _toSelect = true
        }
        else {
            const prevSelectedChoices = fieldR[RUNTIME_INSTANCE_CHOICES]?.filter((choice) => prevValues.includes(choice[CHOICE_ID]))
                    .map((choice) => choice[CHOICE_ID]) ?? []
            valueDifferences = selectedChoices?.filter((choice) => !prevSelectedChoices.includes(choice[CHOICE_ID])) ?? []
        }
        
        if(isAnyChoiceDisabled) return updateInstanceInput(fieldB[FIELD_ID], selectedChoices.map(choice => choice[CHOICE_NAME]), instance?.[RUNTIME_INSTANCE_INSTANCE_ID], false, true, false, valueDifferences[0])

        setSelectedValues((prev) => {
            const nextValue = {...prev}

            // else add it
            const current: TChoice[] = []
            selectedChoices.forEach((choice) => {
                current.push({
                    ...choice,
                    [CHOICE_IS_SELECTED]: true
                })
            })

            if(fieldB[FIELD_ID]) nextValue[fieldKey] = current

            return nextValue
        })
    
        updateInstanceInput(fieldB[FIELD_ID], selectedChoices.map(choice => choice[CHOICE_NAME]), instance?.[RUNTIME_INSTANCE_INSTANCE_ID], false, false, !_toSelect, valueDifferences[0])
    }

    let filteredChoices = isArray(instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]][RUNTIME_INSTANCE_CHOICES] )
        ? [...instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]][RUNTIME_INSTANCE_CHOICES] as TChoice[]]
        : []

    if(choicesSearchTerm){
        filteredChoices = filteredChoices?.filter((value) => toLower(value[CHOICE_NAME]).includes(toLower(choicesSearchTerm)))
    }

    if(limit) {
        filteredChoices = filteredChoices?.slice(0, limit) 
    }   
    
    if(showType === UI_FIELD_TYPES.TEXT.LABEL){
        return <Title title={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_DEFAUT_VALUE] as string} titleClassName="!sb3-font-bold sb3-font-bold sb3-text-lg"/>
    }

    if(fieldB[FIELD_TYPE] === FIELD_TYPES.MULTI_CHOICE ) {
        if(showType === UI_FIELD_TYPES.MULTI_CHOICE.SELECT){
            return <Select 
                name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
                onChange={handleValueSelect} 
                disabled={dataLoading}
                value={(selectedValues as {[x: string]: TChoice[]})?.[fieldKey]?.filter((choice) => choice[CHOICE_IS_SELECTED]) ?? value as TChoice[]}
                required={(required && isArray(value) && value.length === 0)}
                items={instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]][RUNTIME_INSTANCE_CHOICES]}
                isMulti={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_SELECT_TYPE] === SELECT_TYPE.MULTI}
                description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
                info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
                sort={fieldM?.[FIELD_DATA_CONFIGURATION]?.[CONF_PROP_TYPE] !== "list"}
                />
            
        }

        if(showType === UI_FIELD_TYPES.MULTI_CHOICE.RADIO){
            return <div className={`sb3-space-y-1.5 ${filteredChoices.length > 10 && 'sb3-min-w-full'}`}>
            {
                isSearchEnabled &&
                <SearchBar
                    placeholder={`Search ${!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]}`}
                    handleSearchChange = {setChoicesSearchTerm}
                    value={choicesSearchTerm}
                    autoComplete="off"
                />
            }
            <RadioButtons 
                name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
                onChange={handleValueSelect} 
                disabled={dataLoading}
                value={(selectedValues as {[x: string]: TChoice[]})?.[fieldKey]?.filter((choice) => choice[CHOICE_IS_SELECTED]) ?? value as TChoice[]}
                required={(required && isArray(value) && value.length === 0)}
                items={filteredChoices}
                isMulti={false}
                description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
                info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
                sort={fieldM?.[FIELD_DATA_CONFIGURATION]?.[CONF_PROP_TYPE] !== "list"}
                // isMulti={true}
            />
            </div>
        }

        if(showType === UI_FIELD_TYPES.MULTI_CHOICE.CHIPS){
            return <div className="sb3-space-y-1.5 sb3-min-w-full">
            {
                isSearchEnabled &&
                <SearchBar
                    placeholder={`Search ${!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]}`}
                    handleSearchChange = {setChoicesSearchTerm}
                    value={choicesSearchTerm}
                    autoComplete="off"
                />
            }
            <SmallGridChoice 
                name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
                // svg={field[FIELD_DATA_CONFIGURATION][CONF_SVG]}
                onChange={handleValueSelect} 
                disabled={dataLoading}
                value={(selectedValues as {[x: string]: TChoice[]})?.[fieldKey]?.filter((choice) => choice[CHOICE_IS_SELECTED]) ?? value as TChoice[]}
                required={(required && isArray(value) && value.length === 0)}
                items={filteredChoices}
                isMulti={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_SELECT_TYPE] === SELECT_TYPE.MULTI}
                type = {(fieldB.imageSize && ["Big", "Large"].includes(fieldB.imageSize) && activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING) ? "extrabig" : (activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING) ? 'small' : 'big'}
                description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
                info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
                sort={fieldM?.[FIELD_DATA_CONFIGURATION]?.[CONF_PROP_TYPE] !== "list"}
                id={fieldB[FIELD_ID]}
            />
            </div>
        }

    
        if(theme !== THEMES.APPLICO){
            return <div className={`sb3-space-y-1.5 ${filteredChoices.length > 10 && 'sb3-min-w-full'}`}>
            {
                isSearchEnabled &&
                <SearchBar
                    placeholder={`Search ${!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]}`}
                    handleSearchChange = {setChoicesSearchTerm}
                    value={choicesSearchTerm}
                    autoComplete="off"
                />
            }
            <CheckBoxes 
                name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
                onChange={handleValueSelect} 
                disabled={dataLoading}
                value={(selectedValues as {[x: string]: TChoice[]})?.[fieldKey]?.filter((choice) => choice[CHOICE_IS_SELECTED]) ?? value as TChoice[]}
                required={(required && isArray(value) && value.length === 0)}
                items={filteredChoices}
                isMulti={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_SELECT_TYPE] === SELECT_TYPE.MULTI}
                description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
                sort={fieldM?.[FIELD_DATA_CONFIGURATION]?.[CONF_PROP_TYPE] !== "list"}
                info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
                // isMulti={true}
            />
            </div>
            }
        
        return <div className="sb3-space-y-1.5 sb3-min-w-full">
                {
                    isSearchEnabled &&
                    <SearchBar
                        placeholder={`Search ${!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]}`}
                        handleSearchChange = {setChoicesSearchTerm}
                        value={choicesSearchTerm}
                        autoComplete="off"
                    />
                }
                <MultiChoiceGrid
                    dataLoading = {dataLoading}
                    disableClick = {disableClick}
                    disabled={(item: any) => item[CHOICE_IS_DISABLED]}
                    getName = {(item: any) => item[CHOICE_NAME]}
                    handleClick={handleValueSelect}
                    getLabel = {(item: any) => item[CHOICE_NAME]}
                    isConfigure = {true}
                    getImage = {(item) => Utils.getChoiceImage(item, selectedValues)}
                    isDisabledGray = {true}
                    sort={fieldM?.[FIELD_DATA_CONFIGURATION]?.[CONF_PROP_TYPE] !== "list"}
                    value={(selectedValues as {[x: string]: TChoice[]})?.[fieldKey]?.filter((choice) => choice[CHOICE_IS_SELECTED]) ?? value as TChoice[]|undefined}
                    sortType={sort}
                    title = {fieldB ? `Select ${(fieldB)[FRMDATA_FIELD_NAME]}` : "Select Field"}
                    items = {filteredChoices}
                    loading={loading}
                    isMulti={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_SELECT_TYPE] === SELECT_TYPE.MULTI}
                    description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
                    required={(required && isArray(value) && value.length === 0)}
                />
            </div>
    }

    if(fieldB[FIELD_TYPE] === FIELD_TYPES.BOOLEAN){
        return <ToggleField 
            disabled={dataLoading}
            name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
            onChange={(e) => handleInputChange(!!e.target.checked)} 
            value={value ?? false} 
            required={(required && isArray(value) && value.length === 0)}
            description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
            info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
        />
    }


    if(fieldB[FIELD_TYPE] === FIELD_TYPES.DECIMAL || fieldB[FIELD_TYPE] === FIELD_TYPES.INTEGER){
        return <NumberField 
            name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
            onChange={handleInputChange} 
            value={value as number} 
            placeholder={'Qty'} 
            disabled={dataLoading}
            required={(required && isArray(value) && value.length === 0)}
            min={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_MIN]}
            max={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_MAX]}
            increment={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_INCREMENT]}
            description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
            info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
        />
    }

    if(fieldB[FIELD_TYPE] === FIELD_TYPES.FRACTIONS){
        return <div><FractionalField 
            dataLoading={dataLoading}
            disabled={dataLoading}
            title = {`${!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME] ?? "Field"}`}
            items = {instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]][RUNTIME_INSTANCE_CHOICES]}
            min = {fieldM?.[FIELD_DATA_CONFIGURATION][CONF_MIN]}
            max = {fieldM?.[FIELD_DATA_CONFIGURATION][CONF_MAX]}
            value={`${value ?? ""}`}
            increment={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_INCREMENT]}
            defaultValue={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_DEFAUT_VALUE]}
            decimalConversion={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_DECIMAL_CONVERSION]}
            decimalMin={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_DECIMAL_MIN]}
            decimalMax={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_DECIMAL_MAX]}
            units={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_UNITS]}
            labelWholeNumber={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_LABEL_WHOLE_NUMBER]}
            labelFraction={fieldM?.[FIELD_DATA_CONFIGURATION][CONF_LABEL_FRACTION]}
            selectFraction = {handleInputChange}
            description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
            required={(required && isArray(value) && value.length === 0)}
            info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}
        /></div>
    }

    return (
        <TextField 
            id = {fieldB[FIELD_ID]}
            name={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FRMDATA_FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]} 
            disabled={dataLoading}
            onChange={handleInputChange} 
            value={`${value ?? ""}`} 
            placeholder={'Qty'} 
            required={(required && isArray(value) && value.length === 0)}
            maxLength = {fieldM?.[FIELD_DATA_CONFIGURATION][CONF_MAX_CHAR_COUNT]}
            description = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_DESCRIPTION]}
            info = {fieldM?.[FIELD_ADDITIONAL_DATA]?.[ADD_INFO]}

        />
    )
}

export default Field