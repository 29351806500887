import CompatioAnalytics from "helpers/CompatioAnalyticsSb3";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {get as _get} from 'lodash'
import {
    ANCHOR_TYPES,
    CMPT_CONF_BUILDER_TYPE,
    CMPT_CONF_CATEGORY_NAMES,
    CMPT_CONF_DEFAULT,
    PLACEHOLDER_IMAGE
} from "constants/constants";
import { checkVariantHasBuilders } from "api";
import AppContextWrapper from "contexts";
import ThemeSelector from "pages";
import Utils from "Services/Utils";
import { TDCCONFIG } from "types";

type MyProps = {
  type?: string
}

const Anchor = (props: MyProps) => {
  const [isDcShow, setIsDcShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modelId, setModelId] = useState<string>()
  const [models, setModels] = useState<any>([])

  const DcConfigs = _get(window, 'compatioConfig.compatibleProducts.dcConfig');
  const activeCategoryName = _get(window, 'compatioConfig.bigcommerce.activeCategoryName');
  const [dcConfigDetails, setDCConfigDetails] = useState((DcConfigs?.[0] as any)?.details);

  const IsProductSpecificDC = _get(
    window,
    'compatioConfig.compatibleProducts.isProductSpecificDC'
  ) as boolean|undefined ?? false

  const propsNew: string[] = [];

  const trackAnchorClick = () => {
    CompatioAnalytics.track(
      CompatioAnalytics.events.dcAnchorClick,
      {
        baseFinalProductID: Utils.getClientProductSku(),
        baseParentProductID: Utils.getClientProductSku(),
        baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
        clickAction: CompatioAnalytics.clicks.dcGotoWidget,
      },
      'SmartBuilder3',
      undefined,
      'V1',
      "",
      modelId
    );
  };

  // const handleButtonClick = (e: any) => {
  //   const _modelId = e.target.getAttribute("modelId");
  //   if (_modelId) {
  //     window.location.hash = `smartbuilder3&blank=true&modelId=${_modelId}`;
  //   }
  // };

  const handleButtonClick = (e: any) => {
    const _modelId = e.target.getAttribute("modelId")
    if(_modelId){
      setModelId(_modelId)
      setIsOpen(true)
    }
  }

  useEffect(() => {
    const builderButtons = document.querySelectorAll('.compatio-builder-button');
    builderButtons.forEach(button => button.addEventListener('click', handleButtonClick))
  }, [])

  useEffect(() => {
      if (isDcShow == true) {
        let showDataForBuilder =
          IsProductSpecificDC === true
            ? Utils.getClientProductSku()
              ? propsNew[0]
              : props
            : props;
        CompatioAnalytics.track(
          CompatioAnalytics.events.arsRecShow,
          {
            baseFinalProductID: Utils.getClientProductSku(),
            baseParentProductID: Utils.getClientProductSku(),
            baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
            abCell: 1,
            abFactor: 1,
          },
          'SmartBuilder3',
          '',
          'V1',
          "",
          modelId
        );
      }
    }, [isDcShow]);

    useEffect(() => {
      if (!isOpen) {
        // Remove the smartbuilder parameters from URL without page reload
        const url = window.location.href;
        const baseUrl = url.split('#')[0];
        window.history.replaceState({}, document.title, baseUrl);
      }
    }, [isOpen]);

  useEffect(() => {
    let productSku = Utils.getClientProductSku()
    if(!productSku || !Utils.getMerchantKey() || props.type === ANCHOR_TYPES.HIDDEN) return

    checkVariantHasBuilders(productSku, Utils.getMerchantKey() as string)
    .then((res) => {
      const modelTypeDefined = DcConfigs?.find((each) => each[CMPT_CONF_BUILDER_TYPE] === "sb3")
      const sb3Builders = modelTypeDefined ? DcConfigs?.filter(each => each[CMPT_CONF_BUILDER_TYPE] === "sb3") : DcConfigs
      setIsDcShow(true)
      setModels(sb3Builders?.filter((_model) => res?.some((each) => each.project_id === _model.modelId)))
    })
    .catch(() => setIsDcShow(false))
  }, [])

  useEffect(() => {
    const handleModelId = () => {
      const hash = window.location.hash;
      
      // Check if hash starts with #smartbuilder
      if (hash.startsWith('#smartbuilder3')) {
        try {
          // Create URLSearchParams from everything after #smartbuilder
          const params = new URLSearchParams(hash.substring('#smartbuilder3'.length));
          
          // Get modelId from URL or use default
          const urlModelId = params.get('modelId');
          const _modelId = urlModelId || (DcConfigs?.[0]?.modelId?.toString() || '');
  
          if (_modelId) {
            setModelId(_modelId);
            setIsOpen(true);
          }
        } catch (error) {
          console.error('Error parsing URL parameters:', error);
          
          // Fallback to default modelId
          const defaultModelId = DcConfigs?.[0]?.modelId?.toString();
          if (defaultModelId) {
            setModelId(defaultModelId);
            setIsOpen(true);
          }
        }
      }
    };
  
    // Initial check
    handleModelId();
  
    // Optional: Listen for hash changes
    window.addEventListener('hashchange', handleModelId);
    
    // Cleanup
    return () => {
      window.removeEventListener('hashchange', handleModelId);
    };
  }, []); // Empty dependency array as we're setting up the listener only once


  useLayoutEffect(() => {
    const body = document.querySelector('body')
    if(isOpen) {
        if(body) body.style.overflow = "hidden"
        // analyticsPrev.current = window.CompatioAnalytics
        // window.CompatioAnalytics = CompatioAnalytics
    }
    else{ 
        if(body) body.style.overflow = "auto"
        // window.CompatioAnalytics = analyticsPrev.current
    }

    return () => {
        const body = document.querySelector('body')
        if(body) body.style.overflow = "auto"
        // window.CompatioAnalytics = analyticsPrev.current
    };
  }, [isOpen])

  useEffect(() => {
    setDCConfigDetails(DcConfigs?.find(each => each.modelId === modelId)?.details)
  }, [modelId])

  useEffect(() => {
    if(props.type === ANCHOR_TYPES.BANNER){
      const defaultBuilders = DcConfigs?.filter(each => {
          return (!!activeCategoryName ? (each[CMPT_CONF_CATEGORY_NAMES] ?? []).includes(activeCategoryName) : each[CMPT_CONF_DEFAULT] === "1");
        })
        defaultBuilders?.forEach((currentAnchorDetails, key) => {
          if(!currentAnchorDetails.details) return
          
          CompatioAnalytics.track(
            CompatioAnalytics.events.arsRecShow,
            {
              abCell: 1,
              abFactor: 1,
            },
            'SmartBuilder3',
            '',
            'V1',
            "",
            currentAnchorDetails.modelId
          );
      })
    }
  }, [])

  useEffect(() => {
    if(props.type === undefined && models.length > 0){
      models?.forEach((currentAnchorDetails: TDCCONFIG, key: number) => {
        if(!currentAnchorDetails.details) return
          
          CompatioAnalytics.track(
            CompatioAnalytics.events.arsRecShow,
            {
              abCell: 1,
              abFactor: 1,
            },
            'SmartBuilder3',
            '',
            'V1',
            "",
            currentAnchorDetails.modelId
          );
      })
    }
  }, [models])

  if(props.type === ANCHOR_TYPES.HIDDEN) {
    return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen} blank={true}>
        <div>
          <ThemeSelector/>
        </div>
      </AppContextWrapper>
  )
  }

  if(props.type === ANCHOR_TYPES.BANNER && !Utils.isBigCommerce()){ 
    // const currentAnchorDetails = DcConfigs?.find(each => each.modelId === window.compatioConfig?.magento?.modelId)?.details
    const defaultBuilders = DcConfigs?.filter(each => each[CMPT_CONF_DEFAULT] === "1")
    return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen} blank={true}>
        <div className="sb3-contain">
          <div className="sb3-w-full sb3-space-y-2">
            {
              defaultBuilders?.map((currentAnchorDetails, key) => {
                if(!currentAnchorDetails.details) return <Fragment key={key}/>


                return (
                  <div
                    className={`compatio-anchor-sb3 bsb3-order-l sm:sb3-flex sb3-w-full sb3-border sb3-shadow`}
                    key={key}
                  >
                    <div className="sb3-flex sb3-justify-center sm:sb3-w-5/12">
                      <img
                        src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}
                        className="sb3-w-full"
                        alt="Build-Fly-Fishing"
                      />
                    </div>
                    
                    <div className="sb3-m-auto sb3-h-full sb3-p-5 sm:sb3-w-7/12 sb3-w-full">
                      <div className="contentset-dc-anchor sb3-space-y-2">
                        <div className="buildouttext-left">
                          <span className="dc-anchor-text sb3-space-y-2">
                            <p className="!sb3-text-lg">
                              {currentAnchorDetails.details?.description
                                ? currentAnchorDetails.details.description
                                : 'Browse compatible categories for your products and other accessories'}
                            </p>
                          </span>
                        </div>

                        <div className="sb3-text-left">
                          <button 
                          onClick={() => {
                            trackAnchorClick()
                          
                            setIsOpen(true);
                            setModelId(currentAnchorDetails.modelId)
                          }}
                          type="button"
                          className="btn btn--full add-to-cart action primary sb3-border sb3-rounded sb3-px-2 sb3-py-1.5 !sb3-text-lg sb3-min-w-max  focus:!sb3-outline-none">
                            {currentAnchorDetails.details?.title
                              ? currentAnchorDetails.details.title
                              : 'Build a Outfit'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        
      
        <div className="sb3-contain">
          <ThemeSelector/>
        </div>
      </div>
    </AppContextWrapper>
  )
}

if(props.type === ANCHOR_TYPES.BANNER && Utils.isBigCommerce()){ 
  const defaultBuilders = DcConfigs?.filter(each => {
      return (!!activeCategoryName ? (each[CMPT_CONF_CATEGORY_NAMES] ?? []).includes(activeCategoryName) : each[CMPT_CONF_DEFAULT] === "1");
  })

  return (
    <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen} blank={true}>
       <div className="sb3-contain">
      <div className={`sb3-w-full sb3-contain ${!!activeCategoryName && defaultBuilders && defaultBuilders?.length > 1 ? "sb3-grid sb3-gap-4 sb3-grid-cols-1 md:sb3-grid-cols-2" : "sb3-space-y-2"}`}>
        {
          defaultBuilders?.map((currentAnchorDetails, key) => {
            if(!currentAnchorDetails.details) return <Fragment key={key}/>


            const title = currentAnchorDetails.details.title ?? '';
            const description = currentAnchorDetails.details.description ?? '';
            const buttonText = currentAnchorDetails.details.buttonText;
            return (
              <div
                className={`compatio-anchor-sb3 sb3-order-l sb3-items-center ${!!activeCategoryName ? "sb3-p-6 sb3-border-2 sb3-border-[#B1F064]" : "md:sb3-pr-6 sb3-p-3 md:sb3-p-0"} ${!(!!activeCategoryName && defaultBuilders && defaultBuilders?.length > 1) ? "md:sb3-flex sb3-space-y-2 md:sb3-space-y-0" : "sb3-space-y-4"} sb3-gap-4 sb3-w-full  sb3-bg-white sb3-rounded-3xl`}
                key={key}
              >
                {
                  !activeCategoryName &&
                  <div className=' sb3-flex-shrink-0'>
                      <img className="sb3-w-[320px] sb3-h-[140px] sb3-object-cover sb3-rounded-3xl" src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}  alt={'image'}/>
                  </div>
                }
                <div className="sb3-flex-1">
                    <div className="sb3-text-[#052525] sb3-text-xl sb3-font-bold">{title}</div>
                    <div className="sb3-text-[#324f4f] sb3-text-lg sb3-font-normal sb3-mt-[5px] sb3-line-clamp-2">{description}</div>
                </div>
                  {buttonText ?
                      <button
                      style={{border: !activeCategoryName && "2px solid #fb8c53"}}
                      onClick={() => {
                        trackAnchorClick()

                        setIsOpen(true);
                        setModelId(currentAnchorDetails.modelId)
                      }}
                      type="button"
                      className=" button button--primary  focus:!sb3-outline-none sb3-w-full md:sb3-w-max sb3-px-[45px] sb3-py-[18px] sb3-rounded-lg sb3-border-2 sb3-border-[#fb8c53] sb3-justify-center sb3-items-center sb3-text-[#052525] sb3-text-sm sb3-font-bold">
                        {buttonText}
                      </button> : null
                  }
              </div>
            )
          })
        }
      </div>
      </div>
    
  
    <div className="sb3-contain">
      <ThemeSelector/>
    </div>
  </AppContextWrapper>
)
}

if(!isDcShow) return <></>

if(props.type === ANCHOR_TYPES.BUTTON) return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen}>
         <div className="sb3-contain">
    
          <button
          type="button"
          onClick={() => {
            trackAnchorClick()
          
            setIsOpen(true);
          }}
          className={`action sb3-contain secondary  focus:!sb3-outline-none btn btn--full ${!Utils.isShopify() ? "sb3-bg-gray-100 sb3-border-gray-300 sb3-border sb3-rounded sb3-text-xl sb3-px-2 sb3-py-1.5 " : "sb3-bg-black"} sb3-font-semibold !sb3-w-full sb3-mb-[15px] sb3-min-w-max tocart`}
          >
            {dcConfigDetails && dcConfigDetails.buttonText
              ? dcConfigDetails.buttonText
              : 'Create Your Solution'}
          </button>
        </div>

      <div className="sb3-contain">
        <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )

  if(Utils.isBigCommerce()) return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen} blank={true}>
         <div className="sb3-contain">
        <div className={`sb3-w-full sb3-space-y-2 sb3-contain`}>
          {
            models?.map((currentAnchorDetails: TDCCONFIG, key: number) => {
              if(!currentAnchorDetails.details) return <Fragment key={key}/>

              CompatioAnalytics.track(
                CompatioAnalytics.events.arsRecShow,
                {
                  abCell: 1,
                  abFactor: 1,
                },
                'SmartBuilder3',
                '',
                'V1',
                "",
                currentAnchorDetails.modelId
              );

              const title = currentAnchorDetails.details.title ?? '';
              const description = currentAnchorDetails.details.description ?? '';
              const buttonText = currentAnchorDetails.details.buttonText;
              return (
                <div
                  className={`compatio-anchor-sb3 sb3-order-l sb3-p-6 sb3-space-y-4 sb3-w-full  sb3-bg-white sb3-rounded-3xl`}
                  key={key}
                >
                  <div className="sb3-flex sb3-space-x-4">
                    {
                      !activeCategoryName &&
                      <div className=' sb3-flex-shrink-0'>
                          <img className="sb3-w-[155px] sb3-h-[122px] sb3-object-cover sb3-rounded-3xl" src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}  alt={'image'}/>
                      </div>
                    }
                    <div className="sb3-flex-1">
                        <div className="sb3-text-[#052525] sb3-text-xl sb3-font-bold">{title}</div>
                        <div className="sb3-text-[#324f4f] sb3-text-lg sb3-font-normal sb3-mt-[5px] sb3-line-clamp-2">{description}</div>
                    </div>
                  </div>
                  
                  {buttonText ?
                      <button
                      style={{border: "2px solid #fb8c53"}}
                      onClick={() => {
                        trackAnchorClick()

                        setIsOpen(true);
                        setModelId(currentAnchorDetails.modelId)
                      }}
                      type="button"
                      className=" button button--primary  focus:!sb3-outline-none sb3-px-[45px] sb3-py-[18px] sb3-min-w-full sb3-rounded-lg sb3-border-2 sb3-border-[#fb8c53] sb3-justify-center sb3-items-center sb3-text-[#052525] sb3-text-sm sb3-font-bold">
                        {buttonText}
                      </button> : null
                  }
                </div>
              )
            })
          }
        </div>
        </div>
      
    
      <div className="sb3-contain">
        <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )


  return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen}>
         <div className="sb3-contain">
      <div className={`sb3-w-full sb3-space-y-2 sb3-contain`}>
        {
          models?.map((currentAnchorDetails: TDCCONFIG, key: number) => {
          if(!currentAnchorDetails.details) return <Fragment key={key}/>

          const title = currentAnchorDetails.details.title ?? '';
          const description = currentAnchorDetails.details.description ?? '';
          const buttonText = currentAnchorDetails.details.buttonText;

          return <div
            className={`dc-build-anchor-a dc-container-show compatio-anchor-sb3 sb3-pl-5 sb3-border-l sb3-max-w-[234px] sb3-contain`}
            onClick={() => {
              trackAnchorClick()
            
              setModelId(currentAnchorDetails.modelId)
              setIsOpen(true);
            }}
          >
            <img
              src={currentAnchorDetails?.details.imageUrl ?? PLACEHOLDER_IMAGE}
              className="dc-anchor-ff-image sb3-min-h-36 sb3-aspect-video sb3-object-contain"
              alt="Build-Fly-Fishing"
            />
            <div className="sb3-pt-4">
              <div className="contentset-dc-anchor sb3-space-y-2">
                <div className="buildouttext-left">
                  <span className="dc-anchor-text sb3-space-y-2">
                    <strong>
                      <span className="sb3-text-[32px] sb3-text-[#7faadb]">
                        {title
                          ?? 'Build It Out'}
                      </span>
                    </strong>
                    <p className="sb3-text-sm">
                      {description
                        ?? 'Browse compatible categories for your products and other accessories'}
                    </p>
                  </span>
                </div>

                <div className="sb3-text-left builoutbutton-right">
                  <button 
                  type="button"
                  className="action primary anchor btn btn--full sb3-border sb3-rounded sb3-px-2 sb3-py-1.5 sb3-text-xl !sb3-w-full  focus:!sb3-outline-none">
                    {buttonText
                      ?? 'BUILD AN OUTFIT'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          
      })
    }
  </div></div>
      
      <div className="sb3-contain">
            <ThemeSelector/>
      </div>
      
    </AppContextWrapper>
  )
}

export default Anchor