import { useEffect, useState } from "react";
import Utils from "Services/Utils";

type MyProps = {
    message?: string
}

const ContentBlock = (props: MyProps) => {
  const [modifiedMessage, setModifiedMessage] = useState('');

  useEffect(() => {
    if(!props.message) return
    setModifiedMessage(Utils.modifyAnchors(props.message));
  }, [props.message]);

  if(!modifiedMessage) return <></>

  return <div><div className="info-text" dangerouslySetInnerHTML={{ __html: modifiedMessage }}></div></div>
}

export default ContentBlock