import Header from "components/Header";
import { useAppContext } from "contexts/AppContext";
import {
    FRMDATA_CONFIG,
    FRMDATA_DISPLAY_NAME,
    FRMDATA_SMID,
    MODEL_DATA,
    MODEL_MAX_INSTANTIATION,
    MODEL_MIN_INSTANTIATION,
    PAGE_FORM_DATA,
    RUNTIME_INSTANCE_INSTANCE_ID,
} from "constants/constants";
import { TModel,  TRuntimeInstance } from "types";
import Submodel from "components/Submodel";
import BorderlessButton from "components/Button/BorderlessButton";
import AccordionLayout from "components/Accordion/AccordionLayout";
import AccordionHeader from "components/Accordion/AccordionHeader";
import AccordionBody from "components/Accordion/AccordionBody";
import { FaRegTrashAlt } from "react-icons/fa";
import { useEffect } from "react";
import NProgress from 'nprogress'
import { TdisabledModal } from "types/contexts/AppContext";
import DisabledModal from "components/DisabledModal";
import StepsComponent from "components/Steps";
import Navbar from "components/Navbar";
import PageModelIterator from "components/Iterators/PageModelIterator";
// import DisabledModal from "components/DisabledModal";

const CumminsTheme = () => {
    const {
        filteredModelSpec,
        specs,
        uiDefn,
        activePage,
        getInstances,
        createInstance,
        deleteInstance,
        disabledModal,
        setDisabledModal,
        updateInstanceInput,
        dataLoading
    } = useAppContext()

    // const [instances, setInstances] = useState<{[x: string]: number}>({})

    // if(!filteredModelSpec && !uiDefn) return <span>Loading...</span>

    useEffect(() => {
        if(!filteredModelSpec && !uiDefn) {
            NProgress.start()
        }
        else{
            NProgress.done()
        }
        return () => {
            NProgress.done()
        }
    }, [filteredModelSpec, uiDefn])

    return (
        <>
            <Navbar/>
            <div className="sb3-py-3 sb3-max-w-7xl sb3-m-auto">
                <StepsComponent/>
            </div>
            <div className="sb3-content sb3-space-y-4">
                {
                    activePage?.[PAGE_FORM_DATA]?.map((_model, modelKey) => {
                        const model: TModel = specs[_model[FRMDATA_SMID]]

                        if(!model) {
                            // console.error("Model not found", "x2")
                            return <></>
                        }

                        const _minInstantiation = model[MODEL_DATA][MODEL_MIN_INSTANTIATION] ?? 1
                        const _maxInstantiation = model[MODEL_DATA][MODEL_MAX_INSTANTIATION] ?? 1

                        if(_minInstantiation === _maxInstantiation) return <></>
                        const instanceCount = getInstances(_model[FRMDATA_SMID])?.length

                        const handleClick = () => {
                            createInstance(_model[FRMDATA_SMID])
                        }

                        return (
                            <div className="sb3-flex sb3-justify-between sb3-items-center sb3-bg-white sb3-px-4 sb3-py-1 sb3-rounded sb3-border">
                                {_model?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME] && <Header title={_model[FRMDATA_CONFIG][FRMDATA_DISPLAY_NAME]}/>}
                                <span className="sb3-flex sb3-items-center">
                                    <span className="sb3-px-2 sb3-py-1 sb3-bg-gray-200 sb3-text-sm sb3-rounded-full">{instanceCount}</span>
                                    <BorderlessButton onClick={handleClick} disabled = {(instanceCount ?? 0) >= _maxInstantiation || dataLoading} className="sb3-text-primary sb3-min-w-min sb3-font-semibold">Create</BorderlessButton>
                                </span>
                            </div>
                        )
                    })
                }

                {
                activePage?.[PAGE_FORM_DATA] && 
                <div className="sb3-content sb3-space-y-2">

                <PageModelIterator>
                    {({
                        visibleFields,
                        visibleConnectors,
                        visibleCategories,
                        disableInstantiation,
                        title,
                        isNumbered,
                        number,
                        instances,
                        _minInstantiation,
                        // _maxInstantiation,
                        isInstantiable,
                        instance,
                        model,
                        uiModel
                    }) => (
                        <div className="sb3-space-y-1 sb3-bg-white sb3-px-2 sb3-rounded sb3-border">
                            <AccordionLayout disabled = {disableInstantiation}>
                                {(provided) => (
                                <>
                                    <AccordionHeader {...provided.getHeaderProps()}>
                                        <span className="sb3-flex sb3-justify-between sb3-items-center">
                                            <Header 
                                                title={title}
                                                number={isNumbered ? number: undefined}
                                            />
                                            {
                                                (!disableInstantiation && isInstantiable) && 
                                                <BorderlessButton 
                                                className="sb3-min-w-min"
                                                disabled = {instances.length <= _minInstantiation || dataLoading}
                                                onClick = {() => deleteInstance((instance as TRuntimeInstance)[RUNTIME_INSTANCE_INSTANCE_ID])}
                                                >
                                                    <FaRegTrashAlt className={`${(instances.length > _minInstantiation && !dataLoading) ? "sb3-text-black" :  "sb3-text-gray-light"}`}/>
                                                </BorderlessButton>
                                            }
                                        </span>
                                    </AccordionHeader>
                                
                                    <AccordionBody {...provided.getBodyProps()}>
                                        <Submodel 
                                            visibleFields = {visibleFields} 
                                            // visibleConnectors = {visibleConnectors} 
                                            visibleCategories = {visibleCategories} 
                                            instance = {instance} 
                                            model={model} 
                                            uiModel={uiModel}
                                            />
                                    </AccordionBody> 
                                </>
                                )}
                            </AccordionLayout>
                        </div>
                    )}
                </PageModelIterator>

                </div>
                }

                {/* <>
                    {
                        activePage?.[MODEL_FIELDS]
                        ?.filter((field) => field[FIELD_VISIBLE])
                        .map((_field, key) => {
                            const field: TField = specs[_field[FIELD_ID]][MODEL_DATA]

                            return <Field 
                                required={field[FIELD_DATA_CONFIGURATION][CONF_REQUIRED]}
                                field = {field} 
                                isFromRuntime = {false}
                                key={key}
                            />
                        })
                    }

                    {
                        activePage?.[MODEL_UI_CATEGORIES]
                        ?.filter((category) => category[FIELD_VISIBLE])
                        .map((_category, key) => {
                            const category: TProductCategoryField = specs[_category[FIELD_ID]][MODEL_DATA]

                            return (
                                <Category key={key} category={category}/>
                            )
                        })
                    }
                </> */}

                <DisabledModal
                    showModal={disabledModal}
                    closeModal={() => setDisabledModal(null)}
                    onConfirm={() => updateInstanceInput((disabledModal as TdisabledModal).fieldId, (disabledModal as TdisabledModal).items, disabledModal?.instanceId, true, false, false, disabledModal?.item)}
                    loading={false}
                />
            </div>
        </>
    );
}

export default CumminsTheme