import { BUILDER_CONFIG, CATEGORY_ID, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, EXCLUSION_OPTIONS, FIELD_ID, FIELD_TYPE, FIELD_TYPES, FRMDATA_CONFIG, FRMDATA_CONTENT, FRMDATA_EXCLUSION, FRMDATA_FIELD_EXCLUSION, FRMDATA_FIELD_TYPE, FRMDATA_GROUP_LABEL, FRMDATA_ITEMS, FRMDATA_TYPE, MODEL_CATEGORIES, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_LAYOUT, RUNTIME_ELEMENT_TYPES, RUNTIME_INSTANCE_ELEMENT_TYPE, RUNTIME_INSTANCE_FIELD_REQUIRED, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_IS_EXCLUDED, RUNTIME_INSTANCE_IS_INPUT, THEME_SECONDARY } from "constants/constants"
import { TModel, TProductCategory, TRuntimeInstance, TUI_BuilderSubmodel, TUI_Node } from "types"
import Field from "./Field"
import Summary from "components/Summary/Summary"
import { isArray } from "lodash"
import Categories from "./Category/Categories"
import { useAppContext } from "contexts/AppContext"
import { Fragment, useState } from "react"
import NodeHelper from "helpers/NodeHelper"
import { TCartItem } from "types/Cart"
import Utils from "Services/Utils"
import Title from "components/Header/Title"
import ContentBlock from "components/ContentBlock"

type MyProps = {
    model: TModel,
    instance: TRuntimeInstance,
    visibleFields: string[]
    // visibleConnectors: string[]
    visibleCategories: string[]
    uiModel: TUI_BuilderSubmodel
    filterField?: (fieldB: TUI_Node) => boolean
}

const Submodel = ({
    model, 
    instance, 
    visibleFields, 
    uiModel, 
    filterField ,
    visibleCategories
}: MyProps) => {
    const {
        activePage,
        theme,
        specs,
        cart,
        builderSpec,
    } = useAppContext()

    const summaryData = Utils.generateSummaryData(
        model?.[MODEL_DATA][MODEL_FIELDS], 
        instance,
        visibleFields,
    )
    
    const isFieldsDone = NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
        instance, 
        activePage?.[PAGE_LAYOUT],
        true
    )

    const fieldGroups = uiModel[FRMDATA_ITEMS]
        .filter((fieldB) => {
            if(
                fieldB[FIELD_TYPE] === FIELD_TYPES.DIVIDER || 
                fieldB[FIELD_TYPE] === FIELD_TYPES.CONTENT_BLOCK 
            ) return true

            const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]
            
            if(!fieldR) return false
            const _exclusionType = !!fieldB[FRMDATA_FIELD_EXCLUSION] ? fieldB[FRMDATA_FIELD_EXCLUSION] : uiModel[FRMDATA_CONFIG][FRMDATA_EXCLUSION]

            if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD) 
                return (!fieldR[RUNTIME_INSTANCE_IS_EXCLUDED] 
                    || _exclusionType !== EXCLUSION_OPTIONS.HIDE 
                    || !_exclusionType) &&
                    fieldR[RUNTIME_INSTANCE_IS_INPUT] && (!filterField || filterField(fieldB))
            
            return (!fieldR[RUNTIME_INSTANCE_IS_EXCLUDED] 
                || _exclusionType !== EXCLUSION_OPTIONS.HIDE 
                || !_exclusionType)
        })
        .reduce((acc: (TUI_Node|TUI_Node[])[], node) => {
            if(!node[FRMDATA_GROUP_LABEL] || node[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY) {
                acc.push(node)
                return acc
            }

            if(
                acc.length === 0 
                || !isArray(acc[acc.length - 1]) 
                ||(acc as TUI_Node[][])[acc.length - 1][0][FRMDATA_GROUP_LABEL] !== node[FRMDATA_GROUP_LABEL]
            ) {
                acc.push([node])
                return acc
            }

            (acc as TUI_Node[][])[acc.length - 1].push(node)
            return acc
        }, [])

    // const fieldGroups = activePage?.pageName === "Property Location" ? 
    // [
    //     ..._fieldGroups.slice(0, 1),
    //     {id: "map", fieldType: "map", type: "map"},
    //     ..._fieldGroups.slice(1)
    // ]
    // : _fieldGroups


    // const isValidUSZipCode = (zipCode: string): boolean => {
    //     const zipRegex = /^\d{5}(-\d{4})?$/;
    //     return zipRegex.test(zipCode);
    // };

    // const findZipCode = () => {
    //     const zipCodeKey = selectedValues && Object.keys(selectedValues).find(key => key.includes(ZIP_CODE_FIELD));
    //     return zipCodeKey ? selectedValues?.[zipCodeKey][0] : null;
    // };
    
    return (
    <>
        {
            fieldGroups
            .map((fieldB, key) => {
                if(isArray(fieldB)){
                    return <div key={key} className="sb3-space-y-1 sb3-relative">
                        <Title title={fieldB[0][FRMDATA_GROUP_LABEL]} titleClassName="!sb3-text-gray-extraDark !sb3-font-semibold "/>
                        <div className="sb3-flex sb3-flex-wrap sb3-gap-x-5 sb3-gap-y-2">
                        {
                            fieldB.map((node, key1) => {
                                const fieldR = instance[MODEL_DYNAMIC_FIELDS][node[FIELD_ID]]

                                if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD)
                                    return <Field 
                                        required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                                        instance={instance} 
                                        fieldB = {node} 
                                        key={key1}
                                        submodel={model}
                                        uiModel={uiModel}
                                        showType={node[FRMDATA_FIELD_TYPE]}
                                        isGrouped={true}
                                    />

                                return <Fragment key = {key}/>

                            })
                        }
                        </div>
                    </div>
                }

                if(fieldB[FIELD_TYPE] === FIELD_TYPES.DIVIDER) 
                    return <div 
                        key={key} 
                        className="sb3-w-full sb3-border"
                        style={{borderColor: builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]}}
                    />

                if(fieldB[FIELD_TYPE] === FIELD_TYPES.CONTENT_BLOCK){ 
                    return <ContentBlock message={fieldB[FRMDATA_CONTENT]} key={key}/>
                }

                const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]
                if(!fieldR) return <></>

                if(fieldB[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY) {
                    let selectedProducts: TCartItem[] = []
    
                    selectedProducts = Utils.getSelectedProductsFromCategoryIds(cart, [fieldB[FIELD_ID]], instance[RUNTIME_INSTANCE_INSTANCE_ID])

                    const _categories = model?.[MODEL_DATA][MODEL_CATEGORIES].filter((cat) => 
                        fieldB[FIELD_ID] === cat[CATEGORY_ID]
                        && !instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]?.[RUNTIME_INSTANCE_IS_EXCLUDED]
                    )

                    if(_categories.length === 0) return <></>

                    return (
                        <Categories 
                            key={key} 
                            submodel = {model} 
                            categories = {_categories}
                            selectedProducts = {selectedProducts}
                            instance={instance}
                            uiModel={uiModel}
                            load={
                                !Utils.isLinear(theme) 
                                ||isFieldsDone
                            }
                        />
                    )
                }

                // if(fieldB[FRMDATA_TYPE] === FIELD_TYPES.CONNECTOR) return (
                //     <Connector 
                //         instance={instance} 
                //         connector = {connector} 
                //         key={key}
                //     />
                // )
                if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD) return <Field 
                    required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                    instance={instance} 
                    fieldB = {fieldB as TUI_Node} 
                    key={key}
                    submodel={model}
                    uiModel={uiModel}
                    showType={fieldB[FRMDATA_FIELD_TYPE]}
                />
                
                return <Fragment key = {key}/>
            })
        }

        {
            summaryData.length > 0 && <Summary title="Project Requirements"
                items={summaryData}/>
        }
    </>
    )
}

export default Submodel