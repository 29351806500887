import React, { useState, useRef, useEffect } from 'react';
import Utils from "Services/Utils";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { BsPinAngle, BsTextareaResize } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { useAppContext } from 'contexts/AppContext';
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_FILL_BUTTON_BG } from 'constants/constants';
import useResizable from 'hooks/useResizable';
import { TInfoText } from 'types';

type MyProps = {
  data?: TInfoText, 
  id: string, 
  title: string
  iconStyle?: React.CSSProperties
}

const TooltipInfo = ({data, id, title, iconStyle}: MyProps) => {
  const {builderSpec, createNewPopup} = useAppContext()
  const tooltipId = Utils.generate_file_name(5);
  const { size, position, setIsResizing, ref, maxHeight, maxWidth: _maxWidth } = useResizable(data?.size ?? "auto");
  const [modifiedMessage, setModifiedMessage] = useState('');
  const [maxWidth, setMaxWidth] = useState<number>(_maxWidth)
  const iconRef = useRef<HTMLDivElement>(null) 

  useEffect(() => {
    if(!iconRef.current) return;
    setMaxWidth(window.innerWidth - iconRef.current.getBoundingClientRect().left - 200)
  }, [iconRef.current])

  useEffect(() => {
    if(!data || !data.info) return setModifiedMessage('')

    setModifiedMessage(Utils.modifyAnchors(data.info));
  }, [data?.info]);

  const handleIconClick: React.MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation()
    if(!!data?.openInNewWindow && data.trigger === "click"){
      e.preventDefault()
      createNewPopup(title, modifiedMessage, id, data.size)
    }
  }

  const handleIconHover: React.MouseEventHandler<SVGElement> = (e) => {
    if(!!data?.openInNewWindow && data.trigger === "hover"){
      e.preventDefault()
      createNewPopup(title, modifiedMessage, id, data.size)
    }
  }

  if(!data || !modifiedMessage) return <></>

  return (
    <span className="sb3-pointer-events-none" ref={iconRef} id={`tooltip-${tooltipId}`}>
      <HiOutlineInformationCircle
        className="sb3-text-primary !sb3-text-lg sb3-pointer-events-auto focus:sb3-outline-none sb3-fill-none"
        data-tooltip-id={`delta-table-${tooltipId}`}
        onClick={handleIconClick}
        onMouseEnter={handleIconHover}
        style={{
          color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG], stroke: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG],
          ...iconStyle
        }}
      />
      {
        !data.openInNewWindow &&
        <Tooltip
          className={`!sb3-text-white sb3-pointer-events-none sb3-break-normal sb3-z-20 sb3-p-0.5 sb3-whitespace-normal sb3-shadow-lg`}
          clickable={true}
          place='right'
          id={`delta-table-${tooltipId}`}
          style={{
            background: "white",
            color: "black",
            transform: `translate(${position.x}px, ${position.y}px)`,
            width: !!size.width ? size.width : undefined,
            height: !!size.height ? size.height : undefined,
            maxWidth: `${maxWidth}px`,
            maxHeight: `${maxHeight}px`,
            zIndex: 10002
          }}
          border="1px solid #c5c5ca"
          opacity={1}
          delayShow={500}
          events={data.trigger === "click" ? ['click'] : []}
        >
          <span onClick={() => {
              createNewPopup(title, modifiedMessage, id, data.size)
            }} 
            className="sb3-absolute sb3-p-0.5 sb3-text-xs sb3-top-0 sb3-left-full -sb3-translate-x-1/2 -sb3-translate-y-1/2 sb3-border sb3-border-black sb3-rounded-full sb3-text-black sb3-bg-gray sb3-cursor-pointer sb3-pointer-events-auto" >
          <BsPinAngle
            />
          </span>
          <div
            ref={ref}
            className="sb3-text-black sb3-pointer-events-auto sb3-overflow-auto sb3-w-full sb3-h-full sb3-max-h-full sb3-max-w-full"
          >
            <div className="react-tooltip info-text" dangerouslySetInnerHTML={{ __html: modifiedMessage }} />
          </div>
          <BsTextareaResize className="sb3-absolute sb3-bottom-0 sb3-left-full -sb3-translate-x-full sb3-text-black sb3-bg-white sb3-text-xl sb3-cursor-se-resize sb3-pointer-events-auto" onMouseDown={() => setIsResizing(true)}/>
        </Tooltip>
      }
    </span>
  );
};

export default TooltipInfo;