import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FRMDATA_SMID, MODEL_DATA, MODEL_SUB_MODEL, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, SORT, THEME_FILL_BUTTON_BG, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import NodeHelper from "helpers/NodeHelper"
import { Dispatch, SetStateAction } from "react"
import Select, {components} from 'react-select'
import Utils from "Services/Utils"
import { TModel } from "types"

const options = [
    { value: SORT.RECOMMENDED, label: 'Recommended', selected: true },
    { value: SORT.PRICE_ASC, label: 'Price (low to high)' },
    { value: SORT.PRICE_DESC, label: 'Price (high to low)' },
    { value: SORT.ALPHABETICAL_ASC, label: 'Alphabetical (A > Z)' },
    { value: SORT.ALPHABETICAL_DESC, label: 'Alphabetical (Z < A)' },
]

type MyProps = {
    categorySort?: string
    setCategorySort?: Dispatch<SetStateAction<string>>
}

const CustomControl = ({ children, ...props }: any) => {
    const {
      platform,
      builderSpec
  } = useAppContext()

    return (
      <components.Control {...props}>
        <span className="sb3-text-gray-500 sb3-ml-2 sb3-font-bold"
        style={{color: platform === THEME_PLATFORMS.BIG_COMMERCE ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG] : undefined}}
        >Sort By: </span>
        {children}
      </components.Control>
    );
  };

const SB2Sort = (props: MyProps) => {
    const {
        sort,
        setSort,
        activeSubmodel,
        builderConfig,
        activePage,
        specs,
        modelId,
        modelSpec,
        platform,
        builderSpec
    } = useAppContext()

    const sortFun = props.setCategorySort ?? setSort

    const handleSortChange = (val: typeof options[number]) => {
        const config = builderConfig()?.compatibleProducts

        sortFun(prev => {
           if(activeSubmodel) CompatioAnalytics.track(
                CompatioAnalytics.events.xrsSortByChange,
                {
                  baseFinalProductID: Utils.getClientProductSku() ?? "",
                  baseParentProductID: Utils.getClientProductSku() ?? "",
                  baseFinalProductName: (config?.productName && decodeURIComponent(config.productName)) ?? "",
                  categoryID: activeSubmodel[MODEL_SUBMODEL_ID],
                  categoryName: activeSubmodel[MODEL_DATA][MODEL_SUB_MODEL],
                  categoryPosition: (activePage?.[PAGE_FORM_DATA]?.sort((uiModel, uiModel2) => {
                    const submodel: TModel = specs[uiModel[FRMDATA_SMID]]
                    const submodel2: TModel = specs[uiModel2[FRMDATA_SMID]]
                    return +NodeHelper.checkIfSubmodelIsRequired(submodel2) - +NodeHelper.checkIfSubmodelIsRequired(submodel)
                  })?.findIndex(model => model[FRMDATA_SMID] === activeSubmodel[MODEL_SUBMODEL_ID]) ?? 0) + 1,
                  oldSortOrder: prev,
                  newSortOrder: val.value,
                },
                'SmartBuilder3',
                undefined,
                'V1',
                modelSpec?.project.ISRName,
                modelId
            );

            return val.value
        })
    } 

    return <Select 
        components={{ Control: CustomControl }}
        options={options} 
        value={
            options.find(val => val.value === (props.categorySort ?? sort)) ?? { value: SORT.RECOMMENDED, label: 'Recommended', selected: true }
        } 
        onChange={(val: any) => handleSortChange(val)} 
        isSearchable={false} 
        className="lg:sb3-w-[250px] sb3-text-xs sb3-font-bold sb3-w-full sb3-max-w-full"
        styles={{
          control: (provided, state) => ({
            ...provided,
              borderRadius: platform === THEME_PLATFORMS.BIG_COMMERCE ? "0.5rem" : 0,
              backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE ?
                                                  builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] : undefined
          }),
                
        }}
    />
}

export default SB2Sort