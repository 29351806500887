import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoRadioButtonOnOutline } from "react-icons/io5";
import BorderlessButton from "components/Button/BorderlessButton";
import { useAppContext } from "contexts/AppContext";
import NodeHelper from "helpers/NodeHelper";
import Utils from "Services/Utils";
import * as consts from "constants/constants";

export default function LinearPages() {
    const {
        activePage,
        setPage,
        getInstances,
        builderSpec,
        specs
    } = useAppContext();

    let currentIndex = useRef<number|null>(null)
    const pagesNavRef = useRef<HTMLDivElement>(null);
    const [scrollIndicator, setScrollIndicator] = useState({ left: false, right: true });
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        document.querySelector(`#step-submodel-${activePage?.[consts.PAGE_ID]}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, [activePage]);

    const handleScrollIndicator = () => {
        const el = pagesNavRef.current;
        if (!el) return;

        const left = el.scrollLeft > 0;
        const right = el.scrollWidth - el.scrollLeft - el.clientWidth > 1;
        setScrollIndicator({ left, right });
    };

    useLayoutEffect(() => {
        const el = pagesNavRef.current;
        if (el) {
            el.addEventListener('scroll', handleScrollIndicator);
            handleScrollIndicator();
            return () => el.removeEventListener('scroll', handleScrollIndicator);
        }
    }, []);

    const startDragging = (e: any) => {
        setIsDragging(true);
        if(!pagesNavRef.current) return 
        setStartX(e.pageX - pagesNavRef.current.offsetLeft);
        setScrollLeft(pagesNavRef.current.scrollLeft);
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const move = (e: any) => {
        if (!isDragging || !pagesNavRef.current) return;
        e.preventDefault();
        const x = e.pageX - pagesNavRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        pagesNavRef.current.scrollLeft = scrollLeft - walk;
    };

    const isLinear = builderSpec?.[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_NAVIGATION] === "linear";

    const newPages = (builderSpec?.[consts.BUILDER_PAGES].filter((page) => {
        return page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[consts.FRMDATA_SMID]) ?? [];
            return _instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
        });
    }) ?? []);

    const isNextActive = newPages.slice(-1)[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID];
    const isPrevActive = newPages[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID];

    function isElementInViewport(el: HTMLDivElement) {
        if(!pagesNavRef.current) return false
        const rect = el.getBoundingClientRect();
        const containerRect = pagesNavRef.current?.getBoundingClientRect();
        
        return (
            rect.left >= containerRect.left &&
            rect.right <= containerRect.right
        );
    }

    const selectNext = () => {
        if(!isLinear){
            if(newPages[(currentIndex.current ?? -1) + 1]) 
                setPage(newPages[(currentIndex.current ?? -1) + 1][consts.PAGE_ID])
            return``
        }

        let flag = -1
        let currentPage: any = null


        const newPage = newPages.find((page) => {
            currentPage = document.querySelector(`#step-submodel-${page[consts.PAGE_ID]}`)
            if(currentPage && isElementInViewport(currentPage)) {
                flag=0
                return false
            }

            if(flag === 0 && !isElementInViewport(currentPage)) return true
        }) ?? newPages[0]

        document.querySelector(`#step-submodel-${newPage[consts.PAGE_ID]}`)?.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' })

        // let currentPage = document.querySelector(`#step-submodel-${!currentPageIndex.current ? newPages[0][consts.PAGE_ID] : currentPageIndex.current }`)

        // if (currentPage) {
        //     let nextItem = (currentPage.nextElementSibling as HTMLDivElement);
        //     console.log(nextItem)
        //     while (nextItem && nextItem.classList.contains('page-name') && isElementInViewport(nextItem)) {
        //         if(!nextItem.nextElementSibling) break;
        //         nextItem = (nextItem.nextElementSibling as HTMLDivElement);
        //     }
        
        //     if (nextItem) {
        //       nextItem.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' });
        //       currentPageIndex.current = nextItem[consts.PAGE_ID]
        //     }
        // }
    }

    const selectPrev = () => {
        if(!isLinear){
            if(currentIndex && currentIndex.current !== 0 && newPages[(currentIndex.current ?? -1) - 1]) 
                setPage(newPages[(currentIndex.current ?? -1) - 1][consts.PAGE_ID])
            return 
        }

        let flag = -1
        let currentPage: any = null

        const newPage = newPages.findLast((page) => {
            currentPage = document.querySelector(`#step-submodel-${page[consts.PAGE_ID]}`)
            if(currentPage && isElementInViewport(currentPage)) {
                flag=0
                return false
            }

            if(flag === 0 && !isElementInViewport(currentPage)) return true
        }) ?? newPages[0]

        document.querySelector(`#step-submodel-${newPage[consts.PAGE_ID]}`)?.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' })
    }

    if (Object.entries(newPages).length === 0) return <></>;

    return (
        <div className="sb3-relative sb3-max-w-[80%]">
            <div 
                ref={pagesNavRef}
                className="submodelNav sb3-flex sb3-items-center sb3-overflow-x-auto sb3-overflow-y-hidden sb3-py-2 sb3-cursor-grab sb3-select-none"
                style={{
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
                onMouseDown={startDragging}
                onMouseLeave={stopDragging}
                onMouseUp={stopDragging}
                onMouseMove={move}
            >
                {newPages?.map((page, key) => {
                    if(activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]) currentIndex.current = key
                    // check if any of the submodel is required
                    const isRequired = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                        const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                        if(!modelSubmodel) return false
    
                        const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                        if(instances?.length === 0) return false
    
                        return instances?.some(instance => NodeHelper.checkIfSubmodelIsRequiredByRuntimeSpec(
                            instance
                        ))
                    })
    
                    const isDone = page[consts.PAGE_FORM_DATA].every((builderSubmodel) => {
                        const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                        if(!modelSubmodel) return false
    
                        const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                        if(instances?.length === 0) return false
    
                        return instances?.some(instance => NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
                            instance, 
                            page[consts.PAGE_LAYOUT]
                        ))
                    })
                    
                    return (
                        <Fragment key={key}>
                            <div 
                                id={`step-submodel-${page?.[consts.PAGE_ID]}`}
                                className={`sb3-pr-2 page-name`}>
                                    <BorderlessButton 
                                        active={activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]}
                                        onClick={() => !isLinear 
                                            && setPage(page[consts.PAGE_ID])}
                                        className={`sb3-flex sb3-space-x-2 sb3-items-center sb3-w-max sb3-rounded-none sb3-font-semibold hover:!sb3-text-white !sb3-text-white
                                            ${isLinear && "!sb3-cursor-default"}
                                            `}
                                        >
                                        { 
                                            isRequired && isDone ? <FaCheckCircle 
                                            className={`sb3-text-white sb3-text-xl`}/>
                                            :
                                            activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? 
                                                <IoRadioButtonOnOutline className="sb3-text-white sb3-text-xl"/> :
                                                <IoIosRadioButtonOff className="sb3-text-white sb3-text-xl"/>
                                        }
                                        <span className="sb3-text-center sb3-text-base">{page[consts.PAGE_NAME]} {(isRequired && !isDone) ? <span className="sb3-text-red-500">*</span> : ""}</span>
                                    </BorderlessButton>
                            </div>
                            {newPages.length - 1 !== key && <span className="sb3-min-w-[40px] sb3-p-0.5 sb3-h-min sb3-bg-white"></span>}
                        </Fragment>
                    )
                })}
            </div>
            {Utils.checkOverflow(pagesNavRef.current) && (scrollIndicator.left || !isLinear) && (
                <div className="sb3-absolute sb3-bg-primary-hover sb3-left-0 sb3-z-[1000] sb3-top-0 sb3-h-full sb3-flex sb3-items-center">
                    <span className="sb3-absolute sb3-left-full sb3-w-20 sb3-h-full sb3-to-transparent sb3-bg-gradient-to-r sb3-from-primary-hover sb3-pointer-events-none" />
                    {(isPrevActive || isLinear) && (
                        <button type="button" className="sb3-ml-3 sb3-text-black sb3-z-10 sb3-bg-light sb3-rounded sb3-p-1.5  focus:!sb3-outline-none" onClick={selectPrev}>
                            <FaChevronLeft />
                        </button>
                    )}
                </div>
            )}
            {Utils.checkOverflow(pagesNavRef.current) && (scrollIndicator.right || !isLinear) && (
                <div className="sb3-absolute sb3-bg-primary-hover sb3-right-0 sb3-z-[1000] sb3-top-0 sb3-h-full sb3-flex sb3-items-center">
                    <span className="sb3-absolute sb3-right-full sb3-w-20 sb3-h-full sb3-to-primary-hover sb3-bg-gradient-to-r sb3-from-transparent sb3-pointer-events-none" />
                    {(isNextActive || isLinear) && (
                        <button type="button" className="sb3-text-black sb3-mr-3 sb3-z-10 sb3-bg-light sb3-rounded sb3-p-1.5  focus:!sb3-outline-none" onClick={selectNext}>
                            <FaChevronRight />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}
